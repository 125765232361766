import React from 'react';
// import Banner from '../../../../assets/img/Adv-Banner.png';
// import GalleryCard from '../../../Components/GalleryCard';
//
// import Golf from '../../../../assets/img/golf.png';
// import Sprint from '../../../../assets/img/sprint.png';
// import Tennis from '../../../../assets/img/tennis.png';
// import Wrestling from '../../../../assets/img/wrestling.png';
//
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
// import GalleryCardMobile from 'micro-site/Components/GalleryCardMobile';
// import kheloIndia from '../../../../assets/img/Gallery/kheloIndia.png';
// import openingCeremoney from '../../../../assets/img/Gallery/openingCeremoney.png';
// import blank from '../../../../assets/img/Gallery/blank.png';
// import youthNotions from '../../../../assets/img/Gallery/youthNotions.png';
import Section from './Section';
import { useSectionsList } from '../../../queries/hooks';
import { useRouteMatch } from 'react-router-dom';
import ListLoader from '../../../../components/ListLoader';

/* const galleryByCategory = [
  {
    age_category: '',
    date: 'October 21, 2021',
    event: 'Khelo India',
    gender: 'male',
    images: [{ id: 0, img_url: kheloIndia }],
    sport_name: 'Khelo India',
    videos: [],
  },
  {
    age_category: '',
    date: 'October 21, 2021',
    event: 'Opening Ceremony',
    gender: 'male',
    images: [{ id: 0, img_url: openingCeremoney }],
    sport_name: 'Khelo India',
    videos: [],
  },
  {
    age_category: '',
    date: 'October 21, 2021',
    event: 'Youth Notions',
    gender: 'male',
    images: [{ id: 0, img_url: youthNotions }],
    sport_name: 'Khelo India',
    videos: [],
  },
  {
    age_category: '',
    date: '',
    event: '',
    gender: 'male',
    images: [{ id: 0, img_url: blank }],
    sport_name: 'Khelo India',
    videos: [],
  },
] */
export default function Gallery({ userRole }) {
  // const sliderRef = useRef(null);
  /*  const settings = {
                className: 'center',
                centerMode: true,
                slidesToShow: 3,
                initialSlide: 1,
                arrows: false,
                responsive: [
                  {
                    breakpoint: 1240,
                    settings: {
                      className: 'center',
                      centerMode: true,
                      slidesToShow: 2,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      className: 'center',
                      centerMode: true,
                      centerPadding: '10%',
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      className: 'center',
                      centerMode: true,
                      centerPadding: '10%',
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ],
              } */
  // const bannerSetting = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };
  const match = useRouteMatch('/micro-site/:tournament/landing/gallery');
  const profilePageMatch = useRouteMatch(`/${userRole}/profiles/tournaments/:tournamentId/gallery`);
  const sectionListQuery = useSectionsList({
    tournament_id: match?.params?.tournament || profilePageMatch?.params?.tournamentId,
  });
  return (
    <div className="px-4 md:px-16 pb-14 max-w-fhd mx-auto">
      <ListLoader
        queryData={sectionListQuery}
        queryKey={'name'}
        EmptyMessage={'Stay tuned , Images and Videos will be updated shortly'}
      >
        {({ data }) => {
          return <Section sectionName={data.name} userRole={userRole} />;
        }}
      </ListLoader>
      {/*      <div className="flex justify-between items-center md:shadow-shadowBottom md:mb-6 md:py-2 pt-2">
        <h3 className="text-base md:text-2xl font-bold text-gray-750 normal-case md:uppercase font-poppins">
          Videos from Tournament
        </h3>
        <div
          className="flex text-xs md:text-base gap-1 md:gap-2 items-center text-gray-750 font-semibold cursor-pointer border-1 border-gray-750 rounded md:rounded-none md:border-0 px-2 py-1 md:px-0 md:py-0"
          onClick={() => setScreen('more_videos')}
        >
          <p>View All Videos</p>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>
      <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      <div className="mt-6 main-slider">
        <div className="relative">
          <button
            onClick={() => {
              sliderRef.current && sliderRef.current.slickNext();
            }}
            className="hidden md:inline-block absolute -right-4 bg-white rounded-full h-8 w-8 shadow-lg z-10 transform -translate-y-1/2  top-1/2 cursor-pointer"
          >
            <FontAwesomeIcon icon={faAngleRight} className="text-gray-450" />
          </button>
          <button
            onClick={() => {
              sliderRef.current && sliderRef.current.slickPrev();
            }}
            className="hidden md:inline-block  absolute left-0 bg-white rounded-full h-8 w-8 shadow-lg z-10 top-1/2 cursor-pointer transform -translate-y-1/2 -translate-x-1/2"
          >
            <FontAwesomeIcon icon={faAngleLeft} className="text-gray-450" />
          </button>
          <Slider ref={sliderRef} {...settings}>
            <div className=" h-34 md:h-60 md:mx-4 carousel-item relative rounded-lg">
              <img
                className="h-full main_img block m-auto object-cover rounded-lg"
                src={Golf}
                alt=""
              />
              <div className="flex flex-col md:items-end md:flex-row  justify-between absolute top-4 bottom-4 md:bottom-8 md:left-8 left-3 right-3">
                <div>
                  <h4 className="text-white text-xs md:text-lg font-bold mt-1">Golf</h4>
                  <p className="text-white text-xxs md:text-xs md:mt-1">Finals - U19</p>
                </div>
                <p className="text-white text-xs md:text-sm md:hidden">View Schedule</p>
              </div>
            </div>
            <div className="h-34 md:h-60 md:mx-4  carousel-item relative rounded-lg">
              <img
                className="h-full main_img block m-auto object-cover rounded-lg"
                src={Wrestling}
                alt=""
              />
              <div className="flex flex-col md:items-end md:flex-row  justify-between absolute top-4 bottom-4 md:bottom-8 md:left-8 left-3 right-3">
                <div>
                  <h4 className="text-white text-xs md:text-lg font-bold mt-1">Wrestling</h4>
                  <p className="text-white text-xxs md:text-xs md:mt-1">Finals - U19</p>
                </div>
                <p className="text-white text-xs md:text-sm md:hidden">View Schedule</p>
              </div>
            </div>
            <div className="h-34 md:h-60 md:mx-4   carousel-item relative rounded-lg">
              <img
                className="h-full main_img  block m-auto object-cover rounded-lg"
                src={Tennis}
                alt=""
              />
              <div className="flex flex-col md:items-end md:flex-row  justify-between absolute top-4 bottom-4 md:bottom-8 md:left-8 left-3 right-3">
                <div>
                  <h4 className="text-white text-xs md:text-lg font-bold mt-1">Tennis</h4>
                  <p className="text-white text-xxs md:text-xs md:mt-1">Finals - U19</p>
                </div>
                <p className="text-white text-xs md:text-sm md:hidden">View Schedule</p>
              </div>
            </div>
            <div className="h-34 md:h-60 md:mx-4   carousel-item relative rounded-lg">
              <img
                className="h-full main_img  block m-auto object-cover rounded-lg "
                src={Sprint}
                alt=""
              />
              <div className="flex flex-col md:items-end md:flex-row  justify-between absolute top-4 bottom-4 md:bottom-8 md:left-8 left-3 right-3">
                <div>
                  <h4 className="text-white text-xs md:text-lg font-bold mt-1">Sprint</h4>
                  <p className="text-white text-xxs md:text-xs md:mt-1">Finals - U19</p>
                </div>
                <p className="text-white text-xs md:text-sm md:hidden">View Schedule</p>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <div className="mt-10 md:mt-16">
        <div className="flex justify-between items-center md:shadow-shadowBottom md:mb-6 md:py-2 pt-2">
          <h3 className="text-base md:text-2xl font-bold text-gray-750 normal-case md:uppercase font-poppins">
            Gallery by Sport
          </h3>
          <div
            className="flex text-xs md:text-base gap-1 md:gap-2 items-center text-gray-750 font-semibold cursor-pointer border-1 border-gray-750 rounded md:rounded-none md:border-0 px-2 py-1 md:px-0 md:py-0"
            onClick={() => setScreen('more_photos_by_sport')}
          >
            <p>View All Photos</p>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
        <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />

        <div className="hidden lg:flex mt-6 justify-start flex-wrap gap-6">
          {data.map((item, idx) => (
            <GalleryCard
              key={idx}
              item={item}
              redirectTo={`/micro-site/${location.pathname.split('/')[2]}/landing/gallery/${
                item.sport_name
              }`}
            />
          ))}
        </div>
        <div className="mt-6 justify-between flex flex-wrap gap-2 lg:hidden">
          {data.map((item, idx) => (
            <GalleryCardMobile
              key={idx}
              item={item}
              redirectTo={`/micro-site/${location.pathname.split('/')[2]}/landing/gallery/${
                item.sport_name
              }`}
            />
          ))}
        </div>
      </div>
      <div className="mt-10 md:mt-16">
        <div className="flex justify-between items-center md:shadow-shadowBottom md:mb-6 md:py-2 pt-2">
          <h3 className="text-base md:text-2xl font-bold text-gray-750 normal-case md:uppercase font-poppins">
            Gallery by Category
          </h3>
          <div
            className="flex text-xs md:text-base gap-1 md:gap-2 items-center text-gray-750 font-semibold cursor-pointer border-1 border-gray-750 rounded md:rounded-none md:border-0 px-2 py-1 md:px-0 md:py-0"
            onClick={() => setScreen('more_photos_by_sport')}
          >
            <p>View All Photos</p>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
        <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
        <div className="hidden lg:flex mt-6 justify-start flex-wrap gap-6">
          {galleryByCategory.map((item, idx) => (
            <GalleryCard
              key={idx}
              item={item}
              redirectTo={`/micro-site/${location.pathname.split('/')[2]}/landing/gallery/${
                item.sport_name
              }`}
            />
          ))}
        </div>
        <div className="mt-6 justify-between flex flex-wrap gap-2 lg:hidden">
          {galleryByCategory.map((item, idx) => (
            <GalleryCardMobile
              key={idx}
              item={item}
              redirectTo={`/micro-site/${location.pathname.split('/')[2]}/landing/gallery/${
                item.sport_name
              }`}
            />
          ))}
        </div>
      </div>
 */}{' '}
    </div>
  );
}
