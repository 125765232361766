import React from 'react';
import GalleryCard from '../../../Components/GalleryCard';
// import Tennis from '../../../../assets/img/tennis.png';
// import Wrestling from '../../../../assets/img/wrestling.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFolderList } from '../../../queries/hooks';
import ListLoader from '../../../../components/ListLoader';
import GalleryCardMobile from '../../../Components/GalleryCardMobile';

export default function FoldersInSection({ userRole }) {
  const match = useRouteMatch('/micro-site/:tournament/landing/gallery/:section');
  const profilePageMatch = useRouteMatch(
    `/${userRole}/profiles/tournaments/:tournamentId/gallery/:section`
  );
  // const [sport, setSport] = useState('');
  const history = useHistory();
  const folderList = useFolderList({
    section_name: match?.params?.section || profilePageMatch?.params?.section,
  });
  // const settings = {
  //   className:``center',
  //   centerMode: true,
  //   slidesToShow: 3,
  //   initialSlide: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         centerPadding: '40px',
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         centerPadding: '40px',
  //       },
  //     },
  //   ],
  // };
  // const bannerSetting = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  // const filter = ['golf', 'sprint', 'tennis', 'wrestling'];
  // const alsoLike = [
  //   {
  //     sport_name: 'Golf',
  //     gender: 'Male',
  //     age_category: 'U-19',
  //     event: 'Golf',
  //     date: 'October 21, 2021',
  //     status: 'sport',
  //     images: [
  //       {
  //         id: 0,
  //         img_url: Golf,
  //       },
  //     ],
  //     videos: [],
  //   },
  //   {
  //     sport_name: 'Athletics',
  //     gender: 'male',
  //     age_category: 'U-19',
  //     event: 'Athletics',
  //     date: 'October 21, 2021',
  //     status: 'category',
  //     images: [
  //       {
  //         id: 0,
  //         img_url: Sprint,
  //       },
  //     ],
  //     videos: [],
  //   },
  // ];

  return (
    <div className="px-4 md:px-16 pb-14 max-w-fhd mx-auto">
      <div className="mt-10 md:mt-16">
        <div className="flex justify-between items-center md:shadow-shadowBottom md:mb-6 md:py-2 pt-2">
          <div className="flex gap-4 items-center cursor-pointer" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faAngleLeft} />
            <h3 className="text-lg md:text-2xl font-bold text-gray-750 font-poppins">
              {match?.params?.section || profilePageMatch?.params?.section}
            </h3>
          </div>
        </div>
        <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
        <div className="mt-6 flex gap-1.5 flex-wrap justify-center">
          <ListLoader
            queryData={folderList}
            queryKey={'name'}
            EmptyMessage={'Stay tuned , Images and Videos will be updated shortly'}
          >
            {(item, index) => {
              return (
                <>
                  <GalleryCard
                    key={index}
                    data={item.data}
                    redirectTo={`${match?.url || profilePageMatch?.url}/${item.data.name
                      .split('-')
                      .at(-1)}`}
                  />
                  <GalleryCardMobile
                    key={index}
                    data={item.data}
                    redirectTo={`${match?.url || profilePageMatch?.url}/${item.data.name
                      .split('-')
                      .at(-1)}`}
                  />
                </>
              );
            }}
          </ListLoader>
        </div>
      </div>
      {/*
      <div className="mt-10 md:mt-16">
        <Slider {...bannerSetting}>
          <img src={Banner} className="w-full h-auto" alt={''} />
          <img src={Banner} className="w-full h-auto" alt={''} />
          <img src={Banner} className="w-full h-auto" alt={''} />
        </Slider>
      </div>
      <div
        className="text-gray-750 font-semibold cursor-pointer md:shadow-shadowBottom md:mb-6 md:pt-24 md:pb-2 pt-2"
        onClick={() => {
          history.goBack();
        }}
      >
        <h3 className="text-lg md:text-2xl font-bold text-gray-750 font-poppins">
          You might also like
        </h3>
        <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      </div>
      <div className="mt-6 flex gap-6">
        {alsoLike.map((item, idx) => {
          return (
            <div key={idx}>
              <AlsoLike item={item} />
            </div>
          );
        })}
      </div>
*/}
    </div>
  );
}
