import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Loader from 'components/Loader';
import FeatureConfigs from 'utils/FeatureConfigs';
import { Capacitor } from '@capacitor/core';
import MicroSite from 'micro-site/index';
// Authentication module imports
const SignIn = lazy(() => import(/* webpackPrefetch: true */ 'authentication/SignIn'));
const SignUp = lazy(() => import(/* webpackPrefetch: true */ 'authentication/SignUp'));
const ForgotPassword = lazy(() =>
  import(/* webpackPrefetch: true */ 'authentication/ForgotPassword')
);
const ResetPassword = lazy(() =>
  import(/* webpackPrefetch: true */ 'authentication/ResetPassword')
);

// Registration module imports
const RegistrationRedirection = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/Redirection')
);
const CoachManageTeam = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/ManageTeam')
);
const UserRegistration = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/Registration')
);
const UserSportEvents = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/SportEvents/index.js')
);
const UserPayment = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/Payment')
);
const UserPaymentSuccess = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/PaymentSuccess')
);
const CoachRegistrationSuccess = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/RegistrationSuccess')
);
const CoachTeamCreation = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/TeamCreation')
);
const InstitutionSportsSelection = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/SportsSelection')
);
const userVerification = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/Verification')
);

// Profiles module imports
const Account = lazy(() => import(/* webpackPrefetch: true */ 'profiles/Account'));
const NotificationList = lazy(() =>
  import(/* webpackPrefetch: true */ 'profiles/user/Notifications')
);
const Profile = lazy(() => import(/* webpackPrefetch: true */ 'profiles/index'));
const BillingAndInformation = lazy(() =>
  import(/* webpackPrefetch: true */ 'profiles/Account/BillingAndInformation')
);
const MatchAnalytics = lazy(() => import(/* webpackPrefetch: true */ 'profiles/MatchAnalytics'));

// Act module imports
const Accommodation = lazy(() => import(/* webpackPrefetch: true */ 'act/Accommodation'));
const Collection = lazy(() => import(/* webpackPrefetch: true */ 'act/Collection'));
const Transport = lazy(() => import(/* webpackPrefetch: true */ 'act/Transport'));
const Support = lazy(() => import(/* webpackPrefetch: true */ 'act/Support'));
const DigitalScoring = lazy(() => import(/* webpackPrefetch: true */ 'digital-scoring/index'));
const AutoLogin = lazy(() => import(/* webpackPrefetch: true */ '../../authentication/AutoLogin'));

// Reconciliation imports
const Reconciliation = lazy(() => import(/* webpackPrefetch: true */ 'Reconciliation/index'));

export default function Routes() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Switch>
          {/* Authentication module routes */}
          <Route exact path="/">
            <Redirect to={defaultRedirectTo} />
          </Route>
          <Route exact path="/micro-site" component={MicroSite}>
            {/* <Redirect to={defaultRedirectTo} /> */}
          </Route>
          <Route path="/public/score" component={DigitalScoring} />
          <Route path="/auto-login" component={AutoLogin} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route path="/update-password" component={ResetPassword} />

          {/* <Route exact path="/signup"> */}
          {/*  <SignUp step={1} /> */}
          {/* </Route> */}
          <Route exact path="/signup/" component={SignUp} />
          <Route exact path="/signup/:step" component={SignUp} />

          {/* Reconciliation */}
          <Route exact path="/reconciliation/:tournamentId" component={Reconciliation} />

          {/* Registration Redirection */}
          <ProtectedRoute path="/registration/:tournament_id" component={RegistrationRedirection} />
          {/* Registration module routes */}
          <ProtectedRoute
            exact
            path="/coach/registration/:tournament_id/manage-team"
            component={CoachManageTeam}
          />
          <ProtectedRoute
            exact
            path="/coach/registration/:tournament_id/create-team"
            component={CoachTeamCreation}
          />
          <ProtectedRoute
            exact
            path="/:user/registration/:tournament_id/tournament-form"
            component={UserRegistration}
          />
          <ProtectedRoute
            exact
            path="/:user/registration/:tournament_id/sport-events"
            component={UserSportEvents}
          />
          <ProtectedRoute
            exact
            path="/:user/registration/:tournament_id/payment"
            component={UserPayment}
          />
          <ProtectedRoute
            exact
            path="/:user/registration/:tournament_id/payment-success"
            component={UserPaymentSuccess}
          />
          <ProtectedRoute
            exact
            path="/coach/registration/:tournament_id/registration-success"
            component={CoachRegistrationSuccess}
          />
          <ProtectedRoute
            exact
            path="/institution/registration/:tournament_id/sports-selection"
            component={InstitutionSportsSelection}
          />
          <ProtectedRoute
            exact
            path="/:user/registration/:tournament_id/verification"
            component={userVerification}
          />

          {/* Profiles module routes */}
          <ProtectedRoute exact path="/:user/profiles/account-details" component={Account} />
          <ProtectedRoute exact path="/:user/profiles/notifications" component={NotificationList} />
          <ProtectedRoute
            exact
            path="/:user/profiles/billing-details"
            component={BillingAndInformation}
          />
          <ProtectedRoute
            exact
            path={`/:user/profiles/match-analytics/:match_id`}
            component={MatchAnalytics}
          />
          <ProtectedRoute path="/:user/profiles" component={Profile} />

          {/* Act module routes */}
          <ProtectedRoute path="/act/accommodation" component={Accommodation} />
          <ProtectedRoute path="/act/collection" component={Collection} />
          <ProtectedRoute path="/act/transport" component={Transport} />
          <ProtectedRoute path="/act/support" component={Support} />

          {/* Default route */}
          <Route path="/micro-site/:tournament_id" component={MicroSite} />
        </Switch>
      </Suspense>
    </Router>
  );
}

// Set default redirection path based on the platform
export const defaultRedirectTo = Capacitor.isNativePlatform()
  ? '/signup'
  : FeatureConfigs.ALLOW_ROUTE_REDIRECTION && window.location.pathname === '/'
  ? '/signup'
  : '/signup';
