const MODULE_NAME = 'micro-sites';

function generateKeyName(value) {
  return [MODULE_NAME, value];
}

const QUERY_KEYS = {
  GET_TOURNAMENT_DETAILS: generateKeyName('tournament-details'),
  GET_TOURNAMENT_BASIC_INFO: generateKeyName('tournament-basic-information'),
  GET_TOURNAMENT_CONTACT_DETAILS: generateKeyName('tournament-contact-details'),
  GET_TOURNAMENT_PRICE_DETAILS: generateKeyName('tournament-price-details'),
  GET_TOURNAMENT_SPORT_LIST: generateKeyName('tournament-sport-list'),
  GET_SPORT_BASIC_INFO: generateKeyName('sport-basic-info'),
  GET_SPORT_EVENTS_LIST: generateKeyName('sport-event-list'),
  GET_SPORT_EVENTS_LIST_DIRECT: generateKeyName('sport-events-list-direct'),
  GET_SPORT_PRICE_DETAILS: generateKeyName('sport-price-details'),
  GET_DRAW_FORMAT: generateKeyName('get-draw-format'),
  GET_POINTS_MEDALS: generateKeyName('get-points-medals'),
  GET_RANKING_TABLE_LIST: generateKeyName('get-ranking-table-list'),
  GET_WINNER_TABLE_LIST: generateKeyName('get-winner-table-list'),
  GET_PARTICIPANT_LIST: generateKeyName('get-participant-list'),
  GET_SPORT_EVENT: generateKeyName('get-sport-event'),
  GET_DRAW_DATE_LIST: generateKeyName('get-draw-dates-list'),
  GET_SPORT_LIST: generateKeyName('sport-list'),
  GET_GENDER: generateKeyName('gender'),
  GET_AGE_CATEGORY: generateKeyName('age-catetory'),
  GET_SPORT_CATEGORY: generateKeyName('sport-category'),
  GET_SUB_CATEGORY: generateKeyName('sub-category'),
  GET_BUILDERIO_PAGE: generateKeyName('builderio-page'),
  GET_SHOW_RECONCILIATION: generateKeyName('show-reconciliation'),
  GET_RULES_PRICE: generateKeyName('get-rules-price'),
  GET_EVENT_ATTRACTIONS: generateKeyName('get-event-attractions'),
  GET_SECTION_LIST: generateKeyName('get-section-list'),
  GET_SECTION_DETAILS: generateKeyName('get-section-details'),
  GET_FOLDER_LIST: generateKeyName('get-folder-list'),
  GET_FOLDER_DETAILS: generateKeyName('get-folder-details'),
  GET_MEDIA_LIST: generateKeyName('get-media-list'),
  GET_MEDIA_DETAILS: generateKeyName('get-media-details'),
};

export default QUERY_KEYS;
