import React, { useState, useEffect } from 'react';
import 'components/formio/formio.css';
import 'constants/DrawEventForm/style.css';
import { useSportEvent } from 'micro-site/queries/hooks';
import { useHistory } from 'react-router-dom';
import 'constants/DrawEventForm';
import { form, tournament } from 'constants/DrawEventForm/index';
import {
  // usePointsMedals,
  useRankingTableList,
  useWinnerTable,
} from '../../queries/hooks.js';
import MainPage from './components/MainPage';
import RankingTable from './components/RankingTable';
import WinnerList from './components/WinnerList';

export default function index() {
  const history = useHistory();

  // const pointsMedalsQueryData = usePointsMedals({
  //   tournament: '1234',
  // });

  const [sport, setSport] = useState(null);

  const rankingTableListPreviewQueryData = useRankingTableList({
    tournament: history.location.pathname.split('/')[2],
    preview: true,
    sport: sport ? (sport === 'All Sports' ? '' : sport) : '',
    event: '',
    medal: '',
    round: '',
  });

  const rankingTableBackUpQueryData = useRankingTableList({
    tournament: history.location.pathname.split('/')[2],
    preview: true,
    sport: '',
    event: '',
    medal: '',
    round: '',
  });

  const rankingTableListFullQueryData = useRankingTableList({
    tournament: history.location.pathname.split('/')[2],
    preview: false,
    sport: sport ? (sport === 'All Sports' ? '' : sport) : '',
    event: '',
    medal: '',
    round: '',
  });

  const winnerTableQueryData = useWinnerTable({
    tournament: history.location.pathname.split('/')[2],
    recent: 'True',
    sport: sport ? (sport === 'All Sports' ? '' : sport) : '',
  });

  const winnerTableFullQueryData = useWinnerTable({
    tournament: history.location.pathname.split('/')[2],
    recent: 'False',
    sport: sport ? (sport === 'All Sports' ? '' : sport) : '',
  });
  useEffect(() => {
    // console.log('changed', sport);
    winnerTableFullQueryData.refetch();
  }, [sport]);

  const winnerColumns = [
    {
      key: 'athlete_sfa_id',
      label: 'Athlete (Student)',
      component: 'OpponentColumn',
    },
    {
      key: 'sport',
      label: 'Sport',
      component: 'TextColumn',
    },
    {
      key: 'sub_category',
      label: 'Category',
      component: 'TextColumn',
    },
    {
      key: 'sport_event',
      label: 'Gender',
      component: 'TextAndImage',
    },
    {
      key: 'age_group',
      label: 'Age Category',
      component: 'TextColumn',
    },
    {
      key: 'medal',
      label: 'Result',
      component: 'TextAndImage',
    },
  ];

  const columnsTable = [
    {
      key: 'angle_bottom',
      label: '',
      component: 'AngleBottomColumn',
    },
    {
      key: 'rank',
      label: 'Rank',
      component: 'TextColumn',
    },
    {
      key: 'institution',
      label: 'School Name',
      component: 'TextAndImage',
    },
    {
      key: 'gold',
      label: 'Gold',
      component: 'TextColumn',
    },
    {
      key: 'silver',
      label: 'Silver',
      component: 'TextColumn',
    },
    {
      key: 'bronze',
      label: 'Bronze',
      component: 'TextColumn',
    },
    {
      key: 'points',
      label: 'Points',
      component: 'TextColumn',
    },
  ];

  // const [medal, setMedal] = useState(null);
  const [screen, setScreen] = useState('main_page');
  const [categoryList, setCategoryList] = useState([]);

  const [params, setParams] = useState('');
  const sportEventQueryData = useSportEvent({
    tournament: tournament,
    sport: params?.sport,
    gender: params?.gender,
    age_group: params?.age_group,
    sport_category: params?.sport_category,
    sub_category: params?.sub_category,
  });

  const handleDrawFormat = (schema) => {
    setParams(schema);
  };

  useEffect(() => {
    sportEventQueryData.refetch();
  }, [params]);

  useEffect(() => {
    if (
      rankingTableBackUpQueryData.data &&
      rankingTableBackUpQueryData.data.length > 0 &&
      !rankingTableBackUpQueryData?.data?.error
    ) {
      // eslint-disable-next-line
      var tmpArr = [];
      rankingTableBackUpQueryData?.data?.forEach((el) => {
        tmpArr = [...tmpArr, ...el.all_sports];
      });
    }
    setCategoryList(['All Sports', ...new Set(tmpArr)].sort());
    // setCategoryList(['All Sports', ...rankingTableBackUpQueryData.data.at(0).all_sports.sort()]);
  }, [rankingTableBackUpQueryData.data]);

  // useEffect(() => {
  //   rankingTableListBackUpQueryData.data ? setCategoryList(rankingTableListBackUpQueryData.data[0].all_sports) : [];
  // }, [rankingTableListBackUpFullQueryData.data]);

  if (sportEventQueryData.isFetched === true && params) {
    try {
      history.push(
        `/public/score/${sportEventQueryData.data.at(0).digital_scoring_format?.toLowerCase()}/${
          sportEventQueryData.data.at(0).sport_event
        }`
      );
    } catch (error) {
      console.debug(error.message);
    }
  }

  const handleSwitch = (screen) => {
    switch (screen) {
      case 'main_page':
        return (
          <MainPage
            sport={sport}
            setSport={setSport}
            categoryList={categoryList}
            rankingTableListPreviewQueryData={rankingTableListPreviewQueryData}
            winnerTableQueryData={winnerTableQueryData}
            winnerTableFullQueryData={winnerTableFullQueryData}
            form={form}
            columnsTable={columnsTable}
            setScreen={setScreen}
            sportEventQueryData={sportEventQueryData}
            handleDrawFormat={handleDrawFormat}
          />
        );
      case 'ranking_table':
        return (
          <RankingTable
            sport={sport}
            setSport={setSport}
            categoryList={categoryList}
            rankingTableListFullQueryData={rankingTableListFullQueryData}
            columnsTable={columnsTable}
            setScreen={setScreen}
          />
        );
      case 'winner_list':
        return (
          <WinnerList
            sport={sport}
            setSport={setSport}
            categoryList={categoryList}
            winnerTableFullQueryData={winnerTableFullQueryData}
            setScreen={setScreen}
            winnerColumns={winnerColumns}
          />
        );

      default:
        return (
          <MainPage
            sport={sport}
            setSport={setSport}
            categoryList={categoryList}
            rankingTableListPreviewQueryData={rankingTableListPreviewQueryData}
            winnerTableQueryData={winnerTableQueryData}
            winnerTableFullQueryData={winnerTableFullQueryData}
            form={form}
            columnsTable={columnsTable}
            setScreen={setScreen}
          />
        );
    }
  };

  // Driver Code
  return handleSwitch(screen);
}
