import { useState, useEffect, useMemo } from 'react';

/**
 * Hook to perform pagination of an array
 * @param {Array} data - An array
 * @param {number} pageSize - no. of elements on each page
 * @returns {Object}  data, setPage , currentPage, maxPage
 */
export const usePagination = (data, pageSize = 10) => {
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    const temp = [];
    for (let i = 0; i < data?.length; i += pageSize) {
      temp.push(data.slice(i, i + pageSize));
    }
    setPagination(temp);
    setPage(1);
  }, [data, pageSize]);
  useEffect(() => {
    setCurrentData(pagination?.slice(0, page).reduce((prev, current) => [...prev, ...current], []));
  }, [page, pagination]);
  return {
    data: currentData,
    setPage,
    currentPage: page,
    maxPage: useMemo(() => data?.length / pageSize, [pageSize, data]),
  };
};

/**
* calls a callback whenever it is clicked outside the element
 * @param {React.Ref} ref - ref of the container HTML element
 * @param {function} callback - function which is called when click outside
 * 

 */

export function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleOutside(e) {
      console.log(ref.current);
      if (ref.current && !ref.current.contains(e.target)) {
        callback && callback();
      }
    }
    window.addEventListener('mousedown', handleOutside);
    return () => window.removeEventListener('mousedown', handleOutside);
  }, [ref]);
}

/**
 * Hook to return a state which updates after a specific delay, update timer resets if state or delay changes before the update is done
 * @param {*} state
 * @param {number} delay time between state updates
 */

export function useDebounce(state, delay) {
  const [term, setTerm] = useState(state);
  useEffect(() => {
    const timer = setTimeout(() => setTerm(state), delay);
    return () => clearTimeout(timer);
  }, [state, delay]);
  return term;
}
