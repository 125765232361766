import React, { useState, useEffect, useRef } from 'react';
import 'components/formio/formio.css';
import 'constants/DrawEventForm/style.css';
import { useSportEvent, useDrawDateList, useTournamentDetails } from 'micro-site/queries/hooks';
import { useHistory } from 'react-router-dom';
import 'constants/DrawEventForm';
import Table from '../../Components/Table';
import ScheduleTabs from 'micro-site/Components/ScheduleTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectList from 'components/select-list';
import { useParticipantList } from '../../queries/hooks.js';
import Loader from 'components/Loader';
import ListLoader from 'components/ListLoader';
import ResultCard from '../../Components/ResultCard';
import SearchIcon from '../../../assets/img/search_icon.svg';
import drawBanner from '../../../assets/img/DrawsBanner.jpg';
import FilterIcon from '../../../assets/img/Filters.svg';
import WrestlingLogo from '../../../assets/img/WrestlingLogo.png';
import ArcheryLogo from '../../../assets/img/ArcheryLogo.png';
import ShootingLogo from '../../../assets/img/shootingLogo.png';
import JudoLogo from '../../../assets/img/judoLogo.png';
import FencingLogo from '../../../assets/img/FencingLogo.png';
import AthleticsLogo from '../../../assets/img/AthleticsLogo.png';
import BoxingLogo from '../../../assets/img/boxing.png';
import ChessLogo from '../../../assets/img/chess.png';

import microSiteLogo from '../../../assets/img/logo/uttarakhand_logo.jpg';
import microSiteBg from '../../../assets/img/uttarakhand_desktop.jpg';
import DrawEventForm from 'micro-site/Components/DrawEventForm';
import FiltersWrapper from 'components/FiltersWrapper';
import { getDateInMonthDateYear } from 'constants/DateFunctions';

import DetailItemLoader from 'components/DetailItemLoader';
import Header from 'micro-site/Components/Header';
import Draws from './ExpandedMobileView';
export default function index({ enabledScheduled }) {
  const history = useHistory();
  const [date, setDate] = useState('');
  const [sport, setSport] = useState(null);
  const [sportList, setSportList] = useState([]);
  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);
  const [ageList, setAgeList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [opponent, setOpponent] = useState('opponent_1');
  const scrollRef = useRef(null);
  const [viewDrawsScreenMobile, setViewDrawsScreenMobile] = useState(false);

  const participantListQueryData = useParticipantList({
    tournament: history.location.pathname.split('/')[2],
    date: date,
    sport: sport ? (sport === 'All' ? '' : sport) : '',
    gender: gender ? (gender === 'All' ? '' : gender) : '',
    age_group: age ? (age === 'All' ? '' : age) : '',
    round: '',
  });

  function calculateFilters() {
    setSportList([
      'All',
      ...((participantListQueryData?.data && participantListQueryData?.data[0].all_sports.sort()) ||
        []),
    ]);
    setGenderList([
      'All',
      ...((participantListQueryData?.data && participantListQueryData?.data[0].all_genders) || []),
    ]);
    setAgeList([
      'All',
      ...((participantListQueryData?.data && participantListQueryData?.data[0].all_age_groups) ||
        []),
    ]);
  }
  useEffect(() => {
    if (
      participantListQueryData.isFetched &&
      participantListQueryData?.data?.length > 0 &&
      sportList.length === 0 &&
      genderList.length === 0 &&
      ageList.length === 0
    ) {
      calculateFilters();
    }
  }, [participantListQueryData.data]);
  useEffect(() => {
    participantListQueryData.refetch();
  }, [sport, gender, age]);

  const filterData = [
    {
      type: 'dropdown',
      dataCy: 'draws_sport_selector',
      selectedValue: sport,
      setSelectedValue: setSport,
      placeholder: 'Sport',
      listData: sportList,
    },
    {
      type: 'dropdown',
      selectedValue: gender,
      setSelectedValue: setGender,
      placeholder: 'Gender',
      listData: genderList,
    },
    {
      type: 'dropdown',
      selectedValue: age,
      setSelectedValue: setAge,
      placeholder: 'Age',
      listData: ageList,
    },
  ];

  const columns = [
    // {
    //   key: 'array_id',
    //   label: '',
    //   component: 'TextColumn',
    // },
    {
      key: 'sport',
      label: 'Sport',
      component: 'SportColumn',
      showSubCategory: true,
    },
    {
      key: 'match',
      label: 'Round',
      component: 'RoundColumn',
    },
    {
      key: 'venue',
      label: 'Venue',
      component: 'VenueColumn',
    },
    {
      key: 'time',
      label: date ? 'Time' : 'Date & Time',
      component: date ? 'TimeColumn' : 'DateTimeColumn',
    },
    // {
    //   key: 'sfa_id_1',
    //   label: 'SFA ID',
    //   component: 'TextColumn',
    // },
    {
      key: 'opponent_1',
      label: 'Opponent 1',
      component: 'OpponentColumn',
    },
    // {
    //   key: 'sfa_id_2',
    //   label: 'SFA ID',
    //   component: 'TextColumn',
    // },
    {
      key: 'opponent_2',
      label: 'Opponent 2',
      component: 'OpponentColumn',
    },
    {
      key: 'score',
      label: 'Score',
      component: 'ScoreColumn',
      status: true,
      enableLink: true,
      tournamentId: history.location.pathname.split('/')[2],
    },
  ];

  // const [round, setRound] = useState(null);
  // const [categoryList, setCategoryList] = useState([]);
  const [params, setParams] = useState('');
  const [filterPopup, setFilterPopup] = useState(false);
  const [expandedView, setExpandedView] = useState(null);
  const sportEventQueryData = useSportEvent({
    tournament: history.location.pathname.split('/')[2],
    sport: params?.sport,
    gender: params?.gender,
    age_group: params?.age_group,
    sport_category: params?.sport_category,
    sub_category: params?.sub_category,
  });
  const linkCards = [
    {
      image: ArcheryLogo,
      title: 'Archery',
      link: 'Archery-Schedule-SFA-Championship-UK-2022',
    },
    {
      image: FencingLogo,
      title: 'Fencing',
      link: 'Fencing Schedule - SFA Championship UK 2022 (1)',
    },
    {
      image: WrestlingLogo,
      title: 'Wrestling',
      link: 'Wrestling Schedule - SFA Championship UK 2022 (1)',
    },
    {
      image: JudoLogo,
      title: 'Judo',
      link: 'Judo Schedule-SFA Championship UK 2022 (1)',
    },
    {
      image: ShootingLogo,
      title: 'Shooting',
      link: 'Shooting schedule - SFA Championship UK 2022 (1)',
    },
    {
      image: AthleticsLogo,
      title: 'Athletics',
      link: 'Athletics Schedule - SFA Championship UK 2022 (2)',
    },
    {
      image: BoxingLogo,
      title: 'Boxing',
      link: 'Boxing Schedule - SFA Championship UK 2022',
    },
    {
      image: ChessLogo,
      title: 'Chess',
      link: 'Chess Schedule - SFA Championship UK 2022',
    },
  ];
  const drawDateListQueryData = useDrawDateList({
    tournament: history.location.pathname.split('/')[2],
  });
  const [participantsData, setParticipantsData] = useState([]);
  useEffect(() => {
    console.log(participantListQueryData);
    if (participantListQueryData.isFetched && Array.isArray(participantListQueryData.data)) {
      const tempArray = [];
      let previousValue = null;
      for (let i = 0; i < participantListQueryData.data.length; i++) {
        if (!previousValue || previousValue !== participantListQueryData.data[i].match.date) {
          previousValue = participantListQueryData.data[i].match.date;
          tempArray.push({
            date: participantListQueryData.data[i].match.date,
            list: [participantListQueryData.data[i]],
          });
        } else {
          tempArray[tempArray.length - 1].list.push(participantListQueryData.data[i]);
        }
      }
      setParticipantsData(tempArray);
    }
  }, [participantListQueryData.data, participantListQueryData.isFetched]);
  const handleDrawFormat = (schema) => {
    setParams(schema);
  };

  const filterPopupHandler = () => {
    setFilterPopup(!filterPopup);
  };

  const clearFilterHandler = () => {
    setSport('All');
    setGender('All');
    setAge('All');
    setFilterPopup(false);
  };
  const tournamentDetailsQuery = useTournamentDetails({
    tournament_id: location.pathname.split('/')[2],
  });
  useEffect(() => {
    sportEventQueryData.refetch();
  }, [params]);

  if (sportEventQueryData.isFetched === true && params) {
    try {
      history.push(
        `/public/score/${sportEventQueryData.data[0].digital_scoring_format?.toLowerCase()}/${
          sportEventQueryData.data[0].sport_event
        }`
      );
    } catch (error) {
      console.debug(error.message);
    }
  }

  const scroll = (scrollOffset) => {
    const scrollElement = document.getElementById('scroll-container');
    if (scrollElement.scrollWidth > scrollElement.clientWidth) {
      scrollElement.scrollLeft += scrollOffset;
    }
  };

  if (!expandedView)
    return (
      <>
        <DetailItemLoader queryData={tournamentDetailsQuery} queryKey="id">
          {({ data }) => {
            return (
              <Header
                eventName={data?.name}
                city={data?.city}
                eventImage={microSiteLogo}
                banner={microSiteBg}
                // price={data.price}
                remarks={`This is one time tournament fees`}
                // remarks={data.remarks}
                startDate={data?.start_date}
                endDate={data?.end_date}
              />
            );
          }}
        </DetailItemLoader>
        <div className="px-4 md:px-16 max-w-fhd mx-auto">
          {enabledScheduled ? (
            <>
              {!viewDrawsScreenMobile ? (
                <>
                  <div
                    className={`${
                      participantListQueryData?.data?.length === 0 ? '' : 'mt-16'
                    } hidden md:block`}
                  >
                    <div className="shadow-shadowBottom pb-3 mb-8">
                      <h1 className="text-2xl font-bold">View Detailed Schedules</h1>
                    </div>
                    <div className="draw-form">
                      <DrawEventForm
                        handleDrawFormat={handleDrawFormat}
                        isLoading={sportEventQueryData?.isLoading}
                      />
                    </div>
                  </div>
                  {drawDateListQueryData?.data?.length > 1 ? (
                    <div className="flex mb-5 md:mb-8 pt-6 md:pt-0 md:mt-16">
                      <button
                        onClick={() => scroll(-300)}
                        className="justify-start my-auto -mr-4 bg-white z-10 px-3 py-1 rounded-full text-purple-650 shadow-shadowLeftRight"
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </button>
                      <div
                        className="overflow-auto whitespace-nowrap rounded-lg no-scrollbar"
                        ref={scrollRef}
                        id="scroll-container"
                      >
                        <ScheduleTabs
                          scrollRef={scrollRef}
                          setDate={(date) => {
                            setDate(date);
                            setAge('');
                            setSport('');
                            setGender('');
                            setSportList([]);
                            setAgeList([]);
                            setGenderList([]);
                          }}
                        />
                      </div>
                      <button
                        onClick={() => scroll(300)}
                        className="justify-start my-auto -ml-4 bg-white z-10 px-3 py-1 rounded-full text-purple-650 shadow-shadowLeftRight"
                      >
                        <FontAwesomeIcon icon={faAngleRight} />
                      </button>
                    </div>
                  ) : // <div className="w-full text-gray-750">
                  //   <p className="text-center">No Draws</p>
                  // </div>

                  null}
                  <div className="md:flex hidden justify-between">
                    <div className="flex gap-2.5">
                      {
                        <div className="w-40 flex">
                          <FiltersWrapper filters={filterData} />
                        </div>

                        //   <div className="w-full text-gray-750">
                        //   <p className="text-center">No Draws</p>
                        // </div>
                      }
                      {/* <div className="w-40">
            <SelectList
              selectedValue={round}
              setSelectedValue={setRound}
              placeholder="Round"
              listData={categoryList}
            />
          </div> */}
                    </div>
                    {/* <div className="flex gap-2 text-sm py-2.5 px-4 border-1 border-gray-350 rounded-lg items-center">
          <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
          <input
            type="text"
            className="border-0 h-5 text-sm placeholder-gray-450"
            placeholder="Search by Athlete or School"
          />
        </div> */}
                  </div>
                  <div className="mt-4 hidden md:block overflow-y-auto md:mb-8">
                    {participantListQueryData?.data && !participantListQueryData.isRefetching ? (
                      participantListQueryData?.data?.length > 0 ? (
                        <Table
                          columns={columns}
                          rows={participantListQueryData?.data}
                          tableHeight={'500px'}
                          prependRow={
                            date === '' || !date
                              ? (() => {
                                  let previousValue = null;
                                  return function prependRow({ match: { date: currentDate } }) {
                                    if (!previousValue) {
                                      previousValue = new Date(currentDate);
                                      return (
                                        <tr>
                                          <td className="pl-4 py-2.5 text-white items-center text-xs">
                                            {getDateInMonthDateYear(currentDate)}
                                          </td>
                                        </tr>
                                      );
                                    }
                                    let flag = false;
                                    if (
                                      previousValue.getTime() !== new Date(currentDate).getTime()
                                    ) {
                                      flag = true;
                                    }
                                    previousValue = new Date(currentDate);
                                    if (flag)
                                      return (
                                        <tr>
                                          <td className="pl-4 py-2.5 text-white items-center text-xs">
                                            {getDateInMonthDateYear(currentDate)}
                                          </td>
                                        </tr>
                                      );
                                  };
                                })()
                              : null
                          }
                        />
                      ) : (
                        <p className="w-full text-center my-20 text-sm text-gray-750">
                          {' '}
                          Draws & Schedules will be updated soon.
                          <br />
                          Thank you for your patience.
                        </p>
                      )
                    ) : (
                      // <div className="w-full text-gray-750">
                      //   <p className="text-center">No Participants Found</p>
                      // </div>
                      <Loader />
                    )}
                  </div>
                  <div className="md:hidden">
                    <div className="mb-3 md:hidden flex justify-between">
                      <div className="flex gap-1">
                        <img src={SearchIcon} className="hidden" alt="search icon" />
                        <div
                          onClick={filterPopupHandler}
                          className="flex cursor-pointer items-center"
                        >
                          <img
                            src={FilterIcon}
                            className="cursor-pointer w-5 h-5"
                            alt="filter icon"
                          />
                          <p className="text-gray-450 text-sm cursor-pointer">Filters</p>
                        </div>
                      </div>
                      <div
                        className="text-sm font-medium text-blue-350 cursor-pointer"
                        onClick={() => setViewDrawsScreenMobile(true)}
                      >
                        View Draws by Sport
                      </div>
                    </div>
                    <div className="result_card_container min-h-screen bg-white">
                      {participantListQueryData.isFetching ? (
                        <>
                          <Loader className={'mt-4'} />
                        </>
                      ) : !participantListQueryData.data ||
                        participantListQueryData.data?.length === 0 ? (
                        <h1
                          data-cy="data_empty"
                          className="w-full text-center my-20 text-sm text-gray-750"
                        >
                          Draws & Schedules will be updated soon. Thank you for your patience
                        </h1>
                      ) : (
                        <>
                          {date ? (
                            <ListLoader queryData={participantListQueryData} queryKey="result">
                              {({ item, index }) => {
                                return (
                                  <ResultCard
                                    showCategoryInMiddle
                                    enableLinks={true}
                                    data={item}
                                    onClick={() => {
                                      if (
                                        item.opponent_1.name === 'NA' &&
                                        item.opponent_2.name === 'NA'
                                      ) {
                                        return;
                                      }
                                      setExpandedView(item);

                                      console.log(item);
                                    }}
                                  />
                                );
                              }}
                            </ListLoader>
                          ) : (
                            participantsData.map((el, i) => (
                              <div key={i}>
                                <div className="text-xs mb-4 sticky top-0 bg-white py-2 z-5">
                                  {getDateInMonthDateYear(el.date)}
                                </div>
                                {el.list.map((item, i) => (
                                  <ResultCard
                                    showCategoryInMiddle
                                    enableLinks
                                    key={i}
                                    data={item}
                                    onClick={() => {
                                      if (
                                        item.opponent_1.name === 'NA' &&
                                        item.opponent_2.name === 'NA'
                                      ) {
                                        return;
                                      }
                                      setExpandedView(item);
                                    }}
                                  />
                                ))}
                              </div>
                            ))
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={`fixed overflow-y-auto shadow-footerPopUp h-3/4 rounded-t-xl w-full bg-white flex flex-col justify-between px-4 py-6 bottom-0 right-0 left-0 z-20 ${
                      filterPopup ? 'block' : 'hidden'
                    } `}
                  >
                    <div>
                      <div className="flex justify-between">
                        <h3 className="text-base font-bold gray-text-750">Filters </h3>
                      </div>

                      <div className="mt-8">
                        <div className="mb-4 w-full">
                          <p className="text-sm mb-1 ">Sport</p>
                          <SelectList
                            dataCy="draws_sport_selector"
                            selectedValue={sport}
                            setSelectedValue={setSport}
                            placeholder="Sport"
                            listData={sportList}
                            fullWidth={true}
                            filterClose={true}
                            setFilterPopup={() => {}}
                          />
                          <p className="text-gray-750 text-sm shadow-paymentShadow pt-4 mt-4">
                            Gender
                          </p>
                          <SelectList
                            selectedValue={gender}
                            setSelectedValue={setGender}
                            placeholder="Gender"
                            listData={genderList}
                            fullWidth={true}
                            filterClose={true}
                            setFilterPopup={() => {}}
                          />
                          <p className="text-gray-750 text-sm shadow-paymentShadow pt-4 mt-4">
                            {' '}
                            Age
                          </p>
                          <SelectList
                            selectedValue={age}
                            setSelectedValue={setAge}
                            placeholder="Age"
                            listData={ageList}
                            fullWidth={true}
                            filterClose={true}
                            setFilterPopup={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 items-center text-sm md:text-base mt-auto sticky w-full bottom-0 py-2 bg-white">
                      <button
                        onClick={() => setFilterPopup(false)}
                        className="btn btn-primary btn-md btn-block  bg-gray-750 hover:bg-blue-800 flex gap-1 items-center"
                      >
                        <FontAwesomeIcon icon={faCheck} />
                        <p>Apply</p>
                      </button>
                      <button
                        onClick={clearFilterHandler}
                        className="flex gap-1 items-center justify-center cursor-pointer reg-btn-outline-dark mr-1 w-full hover:bg-gray-250 font-semibold rounded-lg mt-2 py-2 text-gray-dark"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                        <p>Clear</p>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <h1 className="text-base text-black font-bold my-3">View Draws by Sport</h1>
                  <DrawEventForm
                    handleDrawFormat={handleDrawFormat}
                    isLoading={sportEventQueryData?.isLoading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="w-full pb-4">
              <div className="shadow-shadowBottom pb-3 mb-8">
                <h1 className="text-2xl font-bold">View Schedules</h1>
              </div>
              <div>
                <div className="flex gap-2 mb-4 flex-wrap">
                  {linkCards.map((el, i) => (
                    <ScheduleCard key={i} {...el} />
                  ))}
                </div>
              </div>
              <img src={drawBanner} className="text-center mx-auto" />
            </div>
          )}
        </div>
      </>
    );
  return (
    <Draws
      expandedView={expandedView}
      opponent={opponent}
      setOpponent={setOpponent}
      setExpandedView={setExpandedView}
    />
  );
}

export function calculateConditions(won, condition = 'completed', draw, text) {
  const hashmap = { won: 1, lost: 0, draw: 'draw' };
  if (condition.toLowerCase() === 'qualified' || condition.toLowerCase() === 'not qualified')
    return condition;

  if (text) return text;
  won = typeof won === 'string' ? hashmap[won.toLowerCase()] : won;
  if (condition.toLowerCase() === 'completed') {
    if (won) return 'Won';
    if (draw || won === 'draw') return 'Draw';
    return 'Lost';
  }
  if (condition.toLowerCase() === 'walkover') {
    if (won) return 'Walkover';

    return 'Did Not Show';
  }
  if (condition.toLowerCase() === 'no_show') {
    return 'Did Not Show';
  }

  if (
    condition.toLowerCase() === 'digital_score' ||
    condition.toLowerCase() === 'digital_scoring_in_progress'
  )
    return 'Yet to play';
}

function ScheduleCard({ image, title, link }) {
  return (
    <div className="bg-white border border-gray-300 flex flex-col  rounded-lg px-4 md:px-4 py-4 md:py-5 w-47% md:w-60 items-center">
      <div className="flex gap-2">
        <img src={image} className="w-12 h-12" />
        <div>
          <div>{title}</div>
          <a
            href={`/static/${link}.pdf`}
            target="_blank"
            rel="noreferrer"
            className="text-xxs md:text-sm"
          >
            View Schedule
          </a>
        </div>
      </div>
    </div>
  );
}
