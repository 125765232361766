import React, { useEffect, useState } from 'react';
// import { usePagination } from '../../../utils/HooksUtils';
import FiltersWrapper from 'components/FiltersWrapper';
// import Card from '../../Components/EventCard';
import InformationCard from '../../Components/InformationCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faAngleRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectList from 'components/select-list';
import { useHistory, useParams } from 'react-router-dom';
import {
  useSportEventBasicInfo,
  // useSportEventList,
  useSportEventListDirect,
  useRankingTableList,
  useWinnerTable,
  useParticipantList,
  useRulesAndPrice,
  useTournamentDetails,
} from '../../queries/hooks';
import ListLoader from 'components/ListLoader';
import HorizontalInformationCard from '../../Components/HorizontalInformationCard';
import Table from 'micro-site/Components/Table';
import RecentWinnersCarousel from 'micro-site/Components/RecentWinnersCarousel';
import MobileRankingTable from 'micro-site/Components/MobileRankingTable';
import ResultCard from 'micro-site/Components/ResultCard';
import WinnerCard from 'micro-site/Components/WinnerCard';
// import Loader from 'components/Loader';
import FilterIcon from '../../../assets/img/Filters.svg';
// import { useShowDigitalScoring } from '../../../profiles/queries/profileHooks';
import Draws from '../DrawsAndSchedule/ExpandedMobileView';
import DetailItemLoader from 'components/DetailItemLoader';
import Header from 'micro-site/Components/Header';
import microSiteLogo from '../../../assets/img/logo/uttarakhand_logo.jpg';
import microSiteBg from '../../../assets/img/uttarakhand_desktop.jpg';
// import { tournament } from 'constants/DrawEventForm/index';

export default function index() {
  const history = useHistory();
  const params = useParams();
  const [data, setData] = useState(null);
  // const [ageGroupList, setAgeGroupList] = useState([]);
  // const [ageGroup, setAgeGroup] = useState(null);
  // const [genderList, setGenderList] = useState([]);
  // const [gender, setGender] = useState(null);
  const [drawsage, setDrawsage] = useState(null);
  const [drawsgender, setDrawsgender] = useState(null);
  const [drawsGenderList, setDrawsGenderList] = useState([]);
  const [drawsAgeList, setDrawsAgeList] = useState([]);
  const [filterPopup, setFilterPopup] = useState(false);
  const [expandedDraws, setExpandedDraws] = useState(null);
  const [opponent, setOpponent] = useState('opponent_1');

  // const digitalScoringQuery = useShowDigitalScoring({
  //   tournamentId: location.pathname.split('/')[2],
  // });

  const rulesAndPrice = useRulesAndPrice({
    tournamentId: location.pathname.split('/')[2],
    sport: params.sport,
  });

  const sportEventBasicInfoQuery = useSportEventBasicInfo({
    tournament: location.pathname.split('/')[2],
    sport: params.sport,
  });

  // const sportEventListQuery = useSportEventList({
  //   tournament: location.pathname.split('/')[2],
  //   sport: params.sport,
  //   age_group: ageGroup ? (ageGroup === 'All' ? '' : ageGroup) : '',
  //   gender: gender ? (gender === 'All' ? '' : gender) : '',
  //   date: '',
  //   fees_range: '',
  // });
  const tournamentDetailsQuery = useTournamentDetails({
    tournament_id: location.pathname.split('/')[2],
  });

  // const {
  //   data: pagination,
  //   setPage,
  //   currentPage: page,
  //   maxPage,
  // } = usePagination(sportEventListQuery.data);

  const sportEventListBackUpQuery = useSportEventListDirect({
    tournament: location.pathname.split('/')[2],
    sport: params.sport,
  });

  useEffect(() => {
    if (sportEventListBackUpQuery.isFetched) {
      setData(sportEventListBackUpQuery?.data);
    }
  }, [sportEventListBackUpQuery.isFetched]);
  const getAgeGroupList = () => {
    // const ageGroupList = [...new Set(data?.map((el) => el.age_group))];
    // setAgeGroupList(['All', ...ageGroupList.sort()]);
  };

  const getGenderList = () => {
    // const genderList = [...new Set(data?.map((el) => el.gender))];
    // setGenderList(['All', ...genderList.sort()]);
  };
  useEffect(() => {
    if (sportEventListBackUpQuery.isFetched) {
      getAgeGroupList();
      getGenderList();
    }
  }, [data]);

  // const sportEventPriceQuery = useSportEventPriceDetails({
  //   tournament: location.pathname.split('/')[2],
  //   sport: params.sport,
  // });
  // const filterData = [
  //   {
  //     type: 'dropdown',
  //     selectedValue: ageGroup,
  //     setSelectedValue: setAgeGroup,
  //     placeholder: 'Age Group',
  //     listData: ageGroupList,
  //     dataCy: 'age_group',
  //   },
  //   {
  //     type: 'dropdown',
  //     selectedValue: gender,
  //     setSelectedValue: setGender,
  //     placeholder: 'Gender',
  //     listData: genderList,
  //     dataCy: 'gender',
  //   },
  // ];

  const rankingTableListFullQueryData = useRankingTableList({
    tournament: location.pathname.split('/')[2],
    preview: true,
    sport: params.sport,
    event: '',
    medal: '',
    round: '',
  });

  const columnsTable = [
    {
      key: 'angle_bottom',
      label: '',
      component: 'AngleBottomColumn',
    },
    {
      key: 'rank',
      label: 'Rank',
      component: 'TextColumn',
    },
    {
      key: 'institution',
      label: 'Institution',
      component: 'TextAndImage',
    },
    {
      key: 'gold',
      label: 'Gold',
      component: 'TextColumn',
    },
    {
      key: 'silver',
      label: 'Silver',
      component: 'TextColumn',
    },
    {
      key: 'bronze',
      label: 'Bronze',
      component: 'TextColumn',
    },
    {
      key: 'points',
      label: 'Points',
      component: 'TextColumn',
    },
  ];

  const winnerTableQueryData = useWinnerTable({
    tournament: location.pathname.split('/')[2],
    recent: 'True',
    sport: params.sport,
  });
  const participantListQueryData = useParticipantList({
    tournament: location.pathname.split('/')[2],
    date: '',
    sport: params.sport,
    round: '',
    gender: drawsgender ? (drawsgender === 'All' ? '' : drawsgender) : '',
    age_group: drawsage ? (drawsage === 'All' ? '' : drawsage) : '',
  });

  useEffect(() => {
    participantListQueryData.refetch();
  }, [drawsgender, drawsage]);

  const clearFilterHandler = () => {
    setDrawsgender('All');
    setDrawsage('All');
    setFilterPopup(false);
  };

  useEffect(() => {
    if (participantListQueryData.isFetched && participantListQueryData?.data?.length > 0) {
      setDrawsGenderList([
        'All',
        ...((participantListQueryData?.data && participantListQueryData?.data[0].all_genders) ||
          []),
      ]);
      setDrawsAgeList([
        'All',
        ...((participantListQueryData?.data && participantListQueryData?.data[0].all_age_groups) ||
          []),
      ]);
    }
  }, [participantListQueryData.data]);

  const filterPopupHandler = () => {
    setFilterPopup(!filterPopup);
  };

  const drawsFilterData = [
    {
      type: 'dropdown',
      selectedValue: drawsgender,
      setSelectedValue: setDrawsgender,
      placeholder: 'Gender',
      listData: drawsGenderList,
    },
    {
      type: 'dropdown',
      selectedValue: drawsage,
      setSelectedValue: setDrawsage,
      placeholder: 'Age',
      listData: drawsAgeList,
    },
  ];

  const drawAndScheduleColumn = [
    {
      key: 'sport',
      label: 'Sport',
      component: 'SportColumn',
      showSubCategory: true,
    },
    {
      key: 'match',
      label: 'Round',
      component: 'RoundColumn',
    },
    {
      key: 'venue',
      label: 'Venue',
      component: 'VenueColumn',
    },
    {
      key: 'time',
      label: 'Date & Time',
      component: 'DateTimeColumn',
    },
    {
      key: 'opponent_1',
      label: 'Opponent 1',
      component: 'OpponentColumn',
    },
    {
      key: 'opponent_2',
      label: 'Opponent 2',
      component: 'OpponentColumn',
    },
    {
      key: 'score',
      label: 'Score',
      component: 'ScoreColumn',
      status: true,
      enableLink: true,
      tournamentId: history.location.pathname.split('/')[2],
    },
  ];

  // useEffect(() => {
  //   if (sportEventListQuery?.data) {
  //     const temp = [];
  //     for (let i = 0; i < sportEventListQuery.data.length; i += pageSize) {
  //       temp.push(sportEventListQuery.data.slice(i, i + pageSize));
  //     }
  //     setPagination(temp);
  //     setPage(0);
  //   }
  // }, [sportEventListQuery.data]);

  // const sportList = pagination.length > 0 ? pagination[0] : [];

  // console.log('pagination', pagination);
  // console.log('pagination', pagination[page]?.length);
  // console.log('sports', sportList);

  if (!expandedDraws)
    return (
      <div className="bg-white w-full">
        <DetailItemLoader queryData={tournamentDetailsQuery} queryKey="id">
          {({ data }) => {
            return (
              <Header
                eventName={data?.name}
                city={data?.city}
                eventImage={microSiteLogo}
                banner={microSiteBg}
                // price={data.price}
                remarks={`This is one time tournament fees`}
                // remarks={data.remarks}
                startDate={data?.start_date}
                endDate={data?.end_date}
              />
            );
          }}
        </DetailItemLoader>
        <div className="md:px-16 px-4 md:pt-20 md:py-8 bg-white gap-2 items-center font-roboto max-w-fhd mx-auto">
          {sportEventBasicInfoQuery.isLoading ? null : (
            <div
              className="flex items-center cursor-pointer"
              onClick={() =>
                history.push(`/micro-site/${location.pathname.split('/')[2]}/landing/sports`)
              }
            >
              <FontAwesomeIcon icon={faArrowLeft} className="fa-xs text-gray-750 mr-3" />
              <p className="text-gray-750 font-bold my-3 md:my-0">Tournament List / Sports List</p>
            </div>
          )}
          <ListLoader
            queryData={sportEventBasicInfoQuery}
            CustomLoader={null}
            queryKey="title"
            className={`${sportEventBasicInfoQuery.isLoading ? 'h-40' : null}`}
          >
            {({ item, index }) => {
              return (
                <HorizontalInformationCard
                  key={index}
                  index={index}
                  len={sportEventBasicInfoQuery.data.length}
                  title={item.title}
                  content={item.content}
                />
              );
            }}
          </ListLoader>
          {/* <div className="mb-3  mt-4">
            <div className={'mb-9'}>
              <h1 className="text-lg md:text-4xl font-bold text-gold-500 font-poppins">Events</h1>
              <div className={'w-16 h-0.5 mt-2 md:mt-3 md:h-2 bg-gold-500'} />
            </div>
            <div className={'flex justify-between'}>
              {sportEventListQuery.isLoading && <Loader />}
              {sportEventListQuery?.data?.length > 0 ? (
                <FiltersWrapper filters={filterData} />
              ) : null}
              <SearchBar width={'w-1/6'} title={'search Events'} />
            </div>
            {sportEventListQuery.data ? (
              sportEventListQuery.data.length !== 0 ? (
                <>
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 mt-10 gap-3">
                    {pagination?.length > 0 ? (
                      pagination.map((item, index) => {
                        console.log(item);

                        return (
                          <div key={index}>
                            <Card
                              data={item}
                              variant="subCategory"
                              canShowDraws={digitalScoringQuery?.data}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <div className="flex items-center justify-end gap-4 mt-6 text-gray-750 font-semibold">
                    {page < maxPage ? (
                      <button
                        disabled={page + 1 === pagination.length}
                        className={`mx-2 cursor-pointer text-black font-semibold  px-4 py-1 rounded flex gap-2 items-center`}
                        onClick={() => {
                          if (page < sportEventListQuery?.data?.length && maxPage) {
                            setPage((el) => el + 1);
                          }
                        }}
                      >
                        <p>View More Events</p>{' '}
                        <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                      </button>
                    ) : null}
                    {page !== 1 ? (
                      <button
                        disabled={page === 0}
                        className={`mx-2 cursor-pointer text-black font-semibold  px-4 py-1 rounded flex gap-2 items-center`}
                        onClick={() => {
                          setPage(1);
                        }}
                      >
                        <p>View Less Events</p>
                        <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                      </button>
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="mt-4">No sport events for selected filters</div>
              )
            ) : null}
          </div> */}

          {
            // winnerTableQueryData.data?.length > 0 &&
            participantListQueryData?.data?.length > 0 ? (
              <div className="mt-8 flex justify-between items-end">
                <p className="md:text-2xl font-bold text-base">Draws and Schedules</p>
                <div
                  className={`flex gap-2 items-center cursor-pointer`}
                  onClick={() => {
                    history.push(
                      `/micro-site/${location.pathname.split('/')[2]}/landing/draws-schedules`
                    );
                  }}
                >
                  <p className="font-semibold text-xs md:text-base">View Full Table</p>
                  <FontAwesomeIcon icon={faAngleRight} className="w-3 h-3" />
                </div>
              </div>
            ) : null
          }
          <div className="md:flex hidden justify-between">
            <div className="flex gap-2.5">
              {participantListQueryData?.data?.length > 0 ? (
                <div className="w-40 flex">
                  <FiltersWrapper filters={drawsFilterData} />
                </div>
              ) : null}
            </div>
          </div>
          {participantListQueryData?.data?.length > 0 ? (
            <>
              <div className="mt-4 overflow-y-auto hidden md:block">
                <Table
                  columns={drawAndScheduleColumn}
                  rows={participantListQueryData?.data}
                  tableHeight={'500px'}
                />
              </div>
              <div className="mt-4 result_card_container block md:hidden">
                <div className="md:hidden">
                  <div className="mb-3 items-center md:hidden flex justify-end">
                    <div className="flex gap-1">
                      <div onClick={filterPopupHandler} className="flex cursor-pointer items-end">
                        <img
                          src={FilterIcon}
                          className="cursor-pointer w-5 h-5"
                          alt="filter icon"
                        />
                        <p className="text-gray-450 text-sm">Filters</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="result_card_container overflow-y-auto"
                    style={{ maxHeight: '500px' }}
                  >
                    <ListLoader queryData={participantListQueryData} queryKey="result">
                      {({ item, index }) => {
                        return (
                          <ResultCard
                            data={item}
                            onClick={() => {
                              if (item.opponent_1.name === 'NA' && item.opponent_2.name === 'NA') {
                                return;
                              }
                              setExpandedDraws(item);
                            }}
                            enableLinks
                            showCategoryInMiddle
                          />
                        );
                      }}
                    </ListLoader>
                  </div>
                </div>
                <div
                  className={`fixed overflow-y-auto shadow-footerPopUp h-3/4 rounded-t-xl w-full bg-white flex flex-col justify-between px-4 py-6 bottom-0 right-0 left-0 z-20 ${
                    filterPopup ? 'block' : 'hidden'
                  } `}
                >
                  <div>
                    <div className="flex justify-between">
                      <h3 className="text-base font-bold gray-text-750">Filters </h3>
                    </div>
                    <div className="mt-8">
                      <div className="mb-8 w-full">
                        <p className="text-sm mb-1 ">Sport</p>
                        <SelectList
                          selectedValue={drawsgender}
                          setSelectedValue={setDrawsgender}
                          placeholder="Gender"
                          listData={drawsGenderList}
                          fullWidth={true}
                          filterClose={true}
                          setFilterPopup={() => {}}
                        />
                        <p className="text-gray-750 text-sm shadow-paymentShadow pt-4 mt-4">Age</p>
                        <SelectList
                          selectedValue={drawsage}
                          setSelectedValue={setDrawsage}
                          placeholder="Age"
                          listData={drawsAgeList}
                          fullWidth={true}
                          filterClose={true}
                          setFilterPopup={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 items-center text-sm md:text-base mt-auto sticky w-full bottom-0 py-2 bg-white">
                    <button
                      onClick={() => setFilterPopup(false)}
                      className="btn btn-primary btn-md btn-block  bg-gray-750 hover:bg-blue-800 flex gap-1 items-center"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      <p>Apply</p>
                    </button>
                    <button
                      onClick={clearFilterHandler}
                      className="flex gap-1 items-center justify-center cursor-pointer reg-btn-outline-dark mr-1 w-full hover:bg-gray-250 font-semibold rounded-lg mt-2 py-2 text-gray-dark"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                      <p>Clear</p>
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {
            // winnerTableQueryData.data?.length > 0 &&
            rankingTableListFullQueryData?.data?.length > 0 ? (
              <div className="mt-16 flex justify-between pb-2 items-end">
                <p className="md:text-2xl font-bold text-base">Ranking Table</p>
                <div
                  className={`flex gap-2 items-center cursor-pointer`}
                  onClick={() =>
                    history.push(`/micro-site/${location.pathname.split('/')[2]}/landing/results`)
                  }
                >
                  <p className="font-semibold text-xs md:text-base">View Full Table</p>
                  <FontAwesomeIcon icon={faAngleRight} className="w-3 h-3" />
                </div>

                {/* <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => setScreen('ranking_table')}
          >
          <p className="font-semibold text-xs md:text-base">View Full Table</p>
          <FontAwesomeIcon icon={faAngleRight} className="w-3 h-3" />
        </div> */}
              </div>
            ) : null
          }
          {/* {rankingTableListFullQueryData.isLoading ? <Loader /> : null} */}
          {
            // winnerTableQueryData.data?.length > 0 &&
            rankingTableListFullQueryData?.data?.length > 0 ? (
              <>
                <div className="mt-4 hidden md:block">
                  <Table
                    columns={columnsTable}
                    rows={rankingTableListFullQueryData.data}
                    tableHeight={'500px'}
                    sport={params.sport}
                    iconAndText={true}
                    icon={true}
                  />
                </div>
                <div className="block md:hidden no-scrollbar">
                  <MobileRankingTable
                    columns={columnsTable}
                    Tabledata={rankingTableListFullQueryData}
                    hideFilter
                    height={'500px'}
                  ></MobileRankingTable>
                </div>
              </>
            ) : null
          }

          {winnerTableQueryData.data?.length > 0 ? (
            <div className="flex justify-between pb-2 items-end mt-16 mb-4">
              <p className="md:text-2xl font-bold text-base">Recent Winners</p>
              <div
                className={`flex gap-2 items-center cursor-pointer`}
                onClick={() => {
                  history.push(`/micro-site/${location.pathname.split('/')[2]}/landing/results`);
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <p className="font-semibold text-xs md:text-base">Winners</p>
                <FontAwesomeIcon icon={faAngleRight} className="w-3 h-3" />
              </div>
              {/* <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => setScreen('winner_list')}
          >
          <p className="font-semibold">View All Winners</p>
          <FontAwesomeIcon icon={faAngleRight} className="w-3 h-3" />
        </div> */}
            </div>
          ) : null}
          <div className="hidden md:block">
            <RecentWinnersCarousel ReactQueryObject={winnerTableQueryData} />
          </div>
          {winnerTableQueryData.data?.length > 0 ? (
            <div className="block md:hidden">
              {' '}
              <div className="mt-4 py-2 rounded-lg bg-blue-dark px-4 md:hidden">
                <ListLoader queryData={winnerTableQueryData} queryKey="winners">
                  {({ item, index }) => {
                    return <WinnerCard key={index} data={item} />;
                  }}
                </ListLoader>
              </div>
            </div>
          ) : null}
          {rulesAndPrice?.data && !rulesAndPrice?.data?.message?.error ? (
            <ListLoader CustomLoader={null} queryData={rulesAndPrice} queryKey="title">
              {({ item, index }) => {
                return (
                  <InformationCard
                    key={index}
                    index={index}
                    color={'purple-500'}
                    len={rulesAndPrice.data.length}
                    title={item.title}
                    content={item.content}
                  />
                );
              }}
            </ListLoader>
          ) : null}
        </div>
      </div>
    );
  return (
    <Draws
      expandedView={expandedDraws}
      setExpandedView={setExpandedDraws}
      opponent={opponent}
      setOpponent={setOpponent}
    />
  );
}
