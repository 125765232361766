import { calculateConditions } from 'micro-site/Landing/DrawsAndSchedule';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getTimeIn12HourFormat } from '../../constants/DateFunctions';

let transformFunctions = {};
const Variants = {
  light: {
    border: `border-1 border-gray-250`,
    top: `bg-gray-125 text-black`,
    middle: `bg-white text-black`,
    bottom: `bg-gray-125 text-black`,
    text: 'text-black',
  },
  dark: {
    top: `bg-blue-light text-white`,
    middle: `bg-blue-dark text-white`,
    bottom: `bg-blue-light text-white`,
    text: 'text-white',
  },
};
export default function ResultCard({
  data: input,
  type,
  footerButton,
  theme = 'dark',
  userData,
  footerButtons,
  sport,
  onClick,
  enableLinks,
  showCategoryInMiddle,
}) {
  // console.log(data);
  const links = {
    'heats / time trials': true,
    'qualifying round (high jump / long jump )': true,
    qualifying_knockout: true,
    'qualifying round - knockout': true,
  };
  // console.log(showCategoryInMiddle);
  const data = useMemo(() => {
    if (transformFunctions[type]) return transformFunctions[type](input, userData, sport);
    return input;
  }, [input]);
  console.log(data);
  return (
    <button
      className={`rounded-lg overflow-hidden mb-3 w-full ${Variants[theme]?.border || ''}`}
      onClick={onClick}
    >
      <div className={`flex justify-between ${Variants[theme]?.top}  text-xxs  py-1 px-3`}>
        <p className={`w-36 truncate text-left`}>
          {!showCategoryInMiddle && data?.sport_event?.sport
            ? `${data?.sport_event?.sport ? `${data?.sport_event?.sport} |` : ''} ${
                data?.sport_event?.sub_category
                  ? `${data?.sport_event?.sub_category} |`
                  : data?.match?.round
                  ? ` ${data.match.round}`
                  : ''
              } ${data?.sport_event?.sub_category && data.match.round ? data.match.round : ''}`
            : ''}
          {showCategoryInMiddle && data?.sport_event?.sport}
        </p>
        <p>{`${data?.sport_event?.gender} | ${data?.sport_event?.age_group}`}</p>
      </div>
      {data?.opponent_1?.name !== 'NA' || data?.opponent_2?.name !== 'NA' ? (
        <div className={`flex ${Variants[theme]?.middle} justify-between py-2 px-1.5 `}>
          <div className="flex gap-1.5 w-1/2">
            <img
              src={data?.opponent_1?.image}
              alt="dp"
              className={`w-8 h-8 rounded-full flex-shrink-0 inline-block my-auto ${
                data?.opponent_1?.won === 1 ? 'border-2 border-green-450' : ''
              } ${data?.opponent_1?.won === 0 ? 'border-2 border-red-350' : ''}`}
            />
            <div className="w-24 flex flex-col items-start justify-between h-auto truncate">
              <p className="text-xxs">{`${
                data?.opponent_1?.name !== 'NA' ? data?.opponent_1?.name : '-'
              }`}</p>
              <div className="flex flex-col gap-1 items-start">
                <span className="text-gray-500 text-mini">
                  {data.opponent_1?.won !== null &&
                  data.opponent_1?.won !== data?.opponent_2?.won ? (
                    data.opponent_1?.won === 1 ? (
                      <span className="bg-green-450 text-white py-0.5 px-1.5 rounded">
                        {calculateConditions(
                          data?.opponent_1?.won,
                          data?.match?.match_status,
                          false,
                          data.opponent_1?.tempText
                        )}
                      </span>
                    ) : (
                      ''
                    )
                  ) : null}

                  {data.opponent_2?.won !== null &&
                  data?.opponent_1?.won !== data?.opponent_2?.won ? (
                    data?.opponent_1?.won === 0 ? (
                      <span className="bg-gray-500 text-white py-0.5 px-1.5 rounded opacity-40">
                        {calculateConditions(
                          data?.opponent_1?.won,
                          data?.match?.match_status,
                          false,
                          data.opponent_1?.tempText
                        )}
                      </span>
                    ) : (
                      ''
                    )
                  ) : null}
                </span>
                <p className="text-mini opacity-60 truncate">{data?.opponent_1?.institution}</p>
              </div>
            </div>
          </div>

          <p className="text-xxs mx-3 flex justify-center items-center">v/s</p>

          {data?.opponent_2?.name === 'NA' ? (
            <div className="text-white flex justify-center items-center gap-1.5 w-1/2">
              <span className={Variants[theme]?.text}>-</span>
            </div>
          ) : (
            <div className="flex gap-1.5 w-1/2">
              <img
                src={data?.opponent_2?.image}
                alt="dp"
                className={`w-8 h-8 rounded-full inline-block my-auto ${
                  data.opponent_2?.won === 1 ? 'border-2 border-green-450' : ''
                } ${data.opponent_2?.won === 0 ? 'border-2 border-red-350' : ''}`}
              />
              <div className="w-24 gap-1 flex flex-col items-start justify-between h-auto truncate">
                <p className="text-xxs">{data?.opponent_2?.name}</p>
                <div className="flex flex-col gap-1 items-start">
                  <span className="text-gray-500 text-mini">
                    {data.opponent_2?.won !== null &&
                    data.opponent_1?.won !== data.opponent_2?.won ? (
                      data.opponent_2?.won === 1 ? (
                        <span className="bg-green-450 text-white py-0.5 px-1.5 rounded">
                          {calculateConditions(
                            data?.opponent_2?.won,
                            data?.match?.match_status,
                            false,
                            data.opponent_2?.tempText
                          )}
                        </span>
                      ) : (
                        ''
                      )
                    ) : null}

                    {data.opponent_2?.won !== null &&
                    data.opponent_1?.won !== data.opponent_2?.won ? (
                      data.opponent_2?.won === 0 ? (
                        <span className="bg-gray-500 py-0.5 px-1.5 rounded text-white opacity-40">
                          {calculateConditions(
                            data?.opponent_2?.won,
                            data?.match?.match_status,
                            false,
                            data.opponent_2?.tempText
                          )}
                        </span>
                      ) : (
                        ''
                      )
                    ) : null}
                  </span>
                  <p className="text-mini opacity-60 truncate">{data.opponent_2?.institution}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : showCategoryInMiddle && data?.sport_event?.sport_image ? (
        <div className={`flex ${Variants[theme]?.middle} justify-between py-2 px-1.5 `}>
          <div className="flex gap-1.5">
            <img
              src={data?.sport_event?.sport_image}
              alt="dp"
              className={`w-6 h-6 rounded-full flex-shrink-0 inline-block my-auto`}
            />
            <div className={` flex flex-col justify-between h-auto w-full`}>
              <p className="text-xxs text-left">{`${
                data?.event_format || data?.sport_event?.sport
              }`}</p>
              <div>
                <p className="text-mini opacity-60 truncate text-left">
                  {data?.sport_event?.sub_category}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className={`flex ${Variants[theme]?.bottom} text-xxs justify-between py-1 px-3`}>
        <p className=" w-36 truncate text-left">{`${data.match?.venue} | ${getTimeIn12HourFormat(
          data.match.time
        )}`}</p>
        <p>
          {/* {console.log(
            enableLinks,
            data?.event_format?.toLowerCase(),
            enableLinks && links[data?.event_format?.toLowerCase()]
          )} */}

          {!(enableLinks && links[data?.event_format?.toLowerCase()]) ? (
            data.opponent_1?.score === 'NA' || !data?.opponent_1?.score ? (
              data?.match?.match_status?.toLowerCase() === 'digital_score' ||
              data?.match?.match_status?.toLowerCase() === 'digital_scoring_in_progress' ? (
                'Yet to Play'
              ) : (
                '-'
              )
            ) : (
              `Score: ` + data.opponent_1?.score
            )
          ) : (
            <div>
              <Link
                to={`/public/score/${data?.event_format?.toLowerCase()}/${data?.event_name}`}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="text-white">
                  {data?.match?.match_status?.toLowerCase() === 'digital_score' ||
                  data?.match?.match_status?.toLowerCase() === 'digital_scoring_in_progress'
                    ? 'View Draws'
                    : 'View Results'}
                </div>
              </Link>
            </div>
          )}
          {footerButtons &&
            footerButtons
              .filter((btn) => btn)
              .map((btn, i) => {
                return (
                  <span key={i}>
                    {' | '} {btn}
                  </span>
                );
              })}
          {footerButton ? (
            <>
              &nbsp;&nbsp;|&nbsp;<a onClick={footerButton.func}>{footerButton.text}</a>
            </>
          ) : null}
        </p>
      </div>
    </button>
  );
}
const qualified = {
  qualified: 'Qualified',
  'did not qualify': 'Did Not Qualify',
  'not qualified': 'Not Qualified',
};
transformFunctions = {
  athlete: (data, userData, sport) => {
    return {
      ...data,
      opponent_1: {
        name: !userData?.full_name || userData?.full_name === '-' ? 'NA' : userData?.full_name,
        image: userData?.image || '',
        institution: userData?.school_name,
        won:
          (data.result !== 'Lost' &&
            data.result !== '-' &&
            data.result.toLowerCase() !== 'did not qualify') + 0,
        score: data.score || 'NA',
        tempText: qualified[data.result.toLowerCase()],
      },
      opponent_2: {
        name: !data?.opponent || data.opponent === '-' ? 'NA' : data?.opponent,
        image: data?.opponent_image || '',
        institution: data.opponent_school,
        won: (data.result === 'Lost' || data.result.toLowerCase() === 'did not qualify') + 0,
        tempText: qualified[data.result.toLowerCase()],
      },
      sport_event: {
        age_group: data.age_group,
        category: 'NA',
        gender: data.gender,
        sport_image: data?.sport_image,
        sub_category: data.sub_category,
        sport: sport,
      },
      match: {
        date: data.date_time,
        time: data.date_time,
        format: 'NA',
        match: data.id,
        round: data.round_name,
        venue: data.venue,
        score: 'NA',
        match_status: data?.match_status,
      },
    };
  },
  coach: (data) => {
    try {
      return {
        ...data,
        opponent_1: {
          name: !data.participant_one || data.participant_one === '-' ? 'NA' : data.participant_one,
          image: data.participant_one_image,
          institution: data.institution_one,
          won: (data.winner === 'participant_one') + 0,
          score: data.participant_one_score || 'NA',
          tempText: data?.winner === 'participant_one' && qualified[data?.result?.toLowerCase()],
        },
        opponent_2: {
          name: !data.participant_two || data.participant_two === '-' ? 'NA' : data.participant_two,
          image: data.participant_two_image,
          institution: data.institution_two,
          won: (data.winner === 'participant_two') + 0,
          tempText: data?.winner === 'participant_two' && qualified[data?.result?.toLowerCase()],
        },
        sport_event: {
          age_group: data.age_category,
          category: 'NA',
          gender: data.gender,
          sport: data.sport,

          sport_image: data?.sport_image,
          sub_category: data.sub_category,
        },
        match: {
          date: data.date_time,
          time: data.date_time,
          format: 'NA',
          match: data.id,
          round: data.round,
          venue: data.venue,
          score: 'NA',
          match_status: data?.match_status,
        },
      };
    } catch (e) {
      console.error(e.message);
      return data;
    }
  },
};
