import React from 'react';
// import male from '../../assets/img/microsite/sports/Vectormale.svg';
import schedule from '../../assets/img/microsite/sports/Vectorschedule.svg';
import { useHistory } from 'react-router-dom';
import { getDateInMonthDate, getDateInMonthDateYear } from '../../constants/DateFunctions';
import { faVenus, faMars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function Card({ data, redirectTo, variant, canShowDraws }) {
  // console.log(data);
  const history = useHistory();
  // const testDate =
  //   /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)/gm;

  // console.log(data);
  return (
    <div
      className={`bg-white border cursor-pointer border-gray-300 flex flex-col ${
        data.img ? 'w-full' : ''
      } rounded-lg px-4 md:px-4 py-4 md:py-5`}
      onClick={() => history.push(redirectTo)}
      data-cy="event_card"
    >
      <div className="flex flex-col justify-between h-full">
        <div
          className={`mt-2 md:mt-0 text-sm md:text-xl ${
            data.img ? 'order-2' : 'order-1 mt-1 h-full'
          } font-bold`}
        >
          <div
            className={`flex flex-col  ${
              !variant === 'subCategory' ? 'mt-2' : 'h-full justify-between'
            } `}
          >
            {variant === 'subCategory' ? (
              <>
                <span className="h-10 md:h-16">{data.title}</span>
                <span className="font-bold mt-2 text-xxs md:text-xs">{data.sub_category}</span>
              </>
            ) : (
              <>
                <span className="mt-4 text-lg">{data.title}</span>
                <span className="text-xxs md:text-xs">{data.sub_category}</span>
              </>
            )}
          </div>
        </div>

        <div
          className={`${data.tags ? 'mt-1 md:mt-2 text-xs order-2 text-gray-500 flex gap-2' : ''}`}
        >
          {data.tags
            ? data.tags.map((d, i) => (
                <div
                  data-cy="gender_age_group"
                  key={i}
                  className={`flex ${d.bg_color} ${d.color} py-0.5 md:py-1 md:px-2 px-1  gap-1.5 rounded items-center`}
                >
                  {d.text?.toLowerCase() === 'male' ? (
                    <FontAwesomeIcon icon={faMars} />
                  ) : d.text?.toLowerCase() === 'female' ? (
                    <FontAwesomeIcon icon={faVenus} />
                  ) : (
                    ''
                  )}

                  {d.icon === 'age_group' ? (
                    <img src={schedule} alt="age_group" className="md:w-3 md:h-3 w-2.5 h-2.5 " />
                  ) : (
                    ''
                  )}
                  <p className={`md:text-xs text-xxs ${d.color}`}>{d.text}</p>
                </div>
              ))
            : null}
        </div>
        {data.img ? (
          <div className="flex flex-col items-center max-h-26">
            <img
              src={data.img}
              alt="sports image"
              className="rounded-lg order-1 w-full h-auto bg-center"
            />
          </div>
        ) : null}
      </div>
      <div className="mt-2 text-xs md:text-sm text-gray-lighter">
        {data.img ? (
          <div>
            {/* <span>{data.detail_tags[1]}</span> */}
            {/* <span className="mx-1">
              <span className="hidden md:inline-block">•</span>
              <span className="md:hidden inline-block"></span>
            </span> */}
            <span>{data.detail_tags[0]}</span>
            <br />
            <span className="text-left text-xs">
              <span>{getDateInMonthDate(data.detail_tags[2].split(' - ')[0])}</span>
              <span className="inline-block">&nbsp;- &nbsp;</span>
              {/* <br/> */}
              <span>{getDateInMonthDateYear(data.detail_tags[2].split(' - ')[1])}</span>
              {/* {testDate.test(data.detail_tags[2])
                ? getDateInMonthDateYear(data.detail_tags[2].split(' - ')[0]) +
                  ' - ' +
                  getDateInMonthDateYear(data.detail_tags[2].split(' - ')[1])
                : data.detail_tags[2]} */}
            </span>
          </div>
        ) : null}

        {/* Redirection to Digital */}
        {variant === 'subCategory' && canShowDraws?.message && (
          <div className="flex justify-center items-center mt-2">
            <button
              className="text-base p-1 font-bold text-gray-750 cursor-pointer rounded-lg border border-gray-750 w-full md:w-3/4"
              onClick={() => {
                console.log(
                  `${window.location.origin}/public/score/${data.type}/${data.sport_event}`
                );
                history.push(`/public/score/${data.type.toLowerCase()}/${data.sport_event}`);
              }}
            >
              View Draws
            </button>
          </div>
        )}

        {/* Redirection to Digital */}
      </div>

      {/* <div className={`flex w-full h-full ${data.img ? '' : 'flex-col items-center'}`}>
        {data.registration_open ? (
          <div>
            <div
              className={`mt-4 md:mt-7 text-xs md:text-sm font-bold ${
                data.img ? 'text-left' : 'text-center'
              }`}
            >
              {data.price} <span className="font-normal text-gray-450">/person</span>
            </div>
            <div className="mt-1 md:mt-4">
              <button className="text-xs md:text-base border border-gray-900 py-1 md:py-1.5 px-3 lg:px-6 font-bold m-auto w-full rounded-lg hover:bg-gray-800 hover:text-white transition-colors transition-delay-200">
                Register Now
              </button>
            </div>
          </div>
        ) : (
          <div className=" h-full flex items-end	">
            {data.status !== null ? (
              <div
                className={`${data.status.color} ${data.status.bg_color} md:mt-7 mt-3.5 text-xxs md:text-xs py-1 px-1.5 rounded-md inline-block`}
              >
                {data.status.text}
              </div>
            ) : null}
          </div>
        )}
      </div> */}
    </div>
  );
}
