import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export default function SelectList({
  fromProfileEvents,
  selectedValue,
  setSelectedValue,
  listData,
  hideBorder,
  placeholder,
  id,
  lastElement,
  dataCy,
  classNameOuterDiv,
  minWidth,
  filterClose,
  setFilterPopup,
}) {
  const border = () => {
    if (hideBorder === true && id === 0) return 'rounded-l-lg border-t-1 border-b-1 border-l-1';
    if (hideBorder === true && id === lastElement)
      return 'rounded-r-lg border-t-1 border-l-1 border-b-1 border-r-1';
    if (hideBorder === true && typeof id === 'number') return 'border-1 border-r-0';
    if (hideBorder === undefined && id === undefined) return 'rounded-lg border-1';
  };

  if (listData.length > 0 && listData[0] !== null)
    return (
      <div data-cy={dataCy} className={`top-16 w-full `}>
        <Listbox value={selectedValue} onChange={(value) => setSelectedValue(value)}>
          <div className={`relative mt-1 ${classNameOuterDiv}`}>
            <Listbox.Button
              className={`relative  w-full py-2 pl-3 pr-10 text-left bg-white ${border()}
            ${
              fromProfileEvents ? 'border-gray-750' : 'border-gray-350'
            } cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75
            focus-visible:ring-white focus-visible:ring-offset-gray-300 focus-visible:ring-offset-2 focus-visible:border-gray-500
            sm:text-sm`}
              style={{ minWidth: minWidth || '120px' }}
            >
              <span
                className={`block truncate text-sm ${
                  fromProfileEvents ? 'text-gray-750' : 'text-gray-450'
                }`}
              >
                {!selectedValue ? placeholder : selectedValue}
              </span>
              <span
                data-cy="filter_show_icon"
                className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
              >
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`${fromProfileEvents ? 'text-gray-750' : 'text-gray-450'}`}
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                style={{ minWidth: '130px' }}
                className="w-full absolute text-center z-10 py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-gray-350 ring-opacity-5 focus:outline-none sm:text-sm"
                onClick={filterClose ? () => setFilterPopup(false) : null}
              >
                {listData?.map((item, idx) => (
                  <Listbox.Option
                    data-cy="list_filter_option"
                    key={idx}
                    className={({ active }) =>
                      `${
                        active
                          ? 'text-gray-900 bg-gray-100 w-full text-center	'
                          : 'text-gray-900 w-full text-center	'
                      } cursor-default select-none relative py-2 px-4 w-full text-center	`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          data-cy="list_filter_value"
                          className={`${
                            selected ? 'font-medium' : 'font-normal'
                          } block truncate text-center	`}
                        >
                          {item}
                        </span>
                        {selected ? (
                          <span
                            className={`${
                              active ? 'text-gray-600' : 'text-gray-600'
                            } absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    );
  else return <></>;
}
