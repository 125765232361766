import React, { useState } from 'react';
import {
  useSportCategory,
  useAgeGroup,
  useSportList,
  useGender,
  useSubCategory,
} from 'micro-site/queries/hooks';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import Rotate from '../../assets/img/rotate-solid.svg';
import Loader from 'components/Loader';

export default function DrawEventForm({ handleDrawFormat, isLoading, customSubmitText }) {
  const location = useLocation();
  const [sport, setSport] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [sportCategory] = useState('');
  const [subCategory, setsubCategory] = useState('');

  const { data: sportData, isFetching: sportDataFetching } = useSportList({
    tournament: location.pathname.split('/')[2],
  });

  const { data: genderData, refetch: refetchGender } = useGender({
    tournament: location.pathname.split('/')[2],
    sport: sport,
  });

  const { data: ageGroupData, refetch: refetchAgeGroup } = useAgeGroup({
    tournament: location.pathname.split('/')[2],
    sport: sport,
    gender: gender,
  });

  const {
    // data: sportCategoryData,
    refetch: refetchSportCategory,
    // isFetched: sportCategoryDataFetched,
  } = useSportCategory({
    tournament: location.pathname.split('/')[2],
    sport: sport,
    gender: gender,
    age_group: age,
  });

  const {
    data: subCategoryData,
    refetch: refetchSubCategory,
    isFetched: subCategoryDataFetched,
  } = useSubCategory({
    tournament: location.pathname.split('/')[2],
    sport: sport,
    gender: gender,
    age_group: age,
    sport_category: sportCategory === '' ? '' : sportCategory.error ? '' : sportCategory,
  });

  const validationSchema = Yup.object({
    sport: Yup.string().required('Sport is required.'),
    gender: Yup.string().required('Gender is required.'),
    ageGroup: Yup.string().required('Age Group is required.'),
  });

  const viewDrawsSchedules = () =>
    async function (values, { setSubmitting }) {
      setSubmitting(true);
      handleDrawFormat({
        sport: values?.sport,
        gender: values?.gender,
        age_group: values?.ageGroup,
        sport_category: values?.sportCategory,
        sub_category: values?.subCategory,
      });
      setSubmitting(false);
    };
  if (sportDataFetching) return <Loader />;
  return (
    <Formik
      initialValues={{
        sport,
        gender,
        ageGroup: age,
        sportCategory,
        subCategory,
      }}
      validationSchema={validationSchema}
      onSubmit={viewDrawsSchedules()}
    >
      {({ isSubmitting, setValues, values }) => (
        <Form id="signIn-form">
          <div className="flex flex-col gap-2 md:gap-0 md:flex-row">
            <div className="flex-1 m-1 mt-2">
              <label className="font-normal text-sm text-gray-750 pb-2 md:pb-1 block">Sports</label>
              <div className="flex flex-wrap w-full relative bg-white items-center border-2 overflow-hidden rounded-lg">
                <Field
                  as="select"
                  name="sport"
                  onChange={(e) => {
                    setValues({ ...values, sport: e.target.value });
                    setSport(e.target.value);
                    refetchGender();
                  }}
                  className=" text-gray-450 bg-red flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                >
                  <option label="Select Sport" value=""></option>
                  {sportData?.map((el, i) => (
                    <option key={i} value={el} label={el}>
                      {el}
                    </option>
                  ))}
                </Field>
              </div>
              <ErrorMessage name="sport" component="div" className="flex-1 text-red-400" />
            </div>

            <div className="flex-1 m-1 mt-2">
              <label className="font-normal text-sm text-gray-750 pb-2 md:pb-1 block">Gender</label>
              <div className="flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg overflow-hidden">
                <Field
                  as="select"
                  name="gender"
                  placeholder="Select Gender"
                  onChange={(e) => {
                    setValues({ ...values, gender: e.target.value });
                    setGender(e.target.value);
                    refetchAgeGroup();
                  }}
                  className="text-gray-450 bg-red flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                >
                  <option label="Select Gender" value=""></option>
                  {genderData?.map((el, i) => (
                    <option key={i} value={el}>
                      {el}
                    </option>
                  ))}
                </Field>
              </div>
              <ErrorMessage name="gender" component="div" className="flex-1 text-red-400" />
            </div>
            <div className="flex-1 m-1 mt-2">
              <label className="font-normal text-sm text-gray-750 pb-2 md:pb-1 block">
                Age Group
              </label>
              <div className="overflow-hidden flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg">
                <Field
                  as="select"
                  name="ageGroup"
                  onChange={(e) => {
                    setValues({ ...values, ageGroup: e.target.value });
                    setAge(e.target.value);
                    refetchSportCategory();
                  }}
                  className="text-gray-450 bg-red flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                >
                  <option label="Select Age Group" value=""></option>
                  {ageGroupData?.map((el, i) => (
                    <option key={i} value={el}>
                      {el}
                    </option>
                  ))}
                </Field>
              </div>
              <ErrorMessage name="ageGroup" component="div" className="flex-1 text-red-400" />
            </div>
          </div>
          {age === '' ? null : (
            <div className="flex flex-col md:flex-row md:mt-5">
              {/* {(sportCategoryData?.length === 1 && sportCategoryData[0] === 'None') ||
              (sportCategoryData?.length === 2 &&
                sportCategoryData[0] === 'None' &&
                sportCategoryData[1] === null) ||
              (sportCategoryData?.length === 2 &&
                sportCategoryData[0] === null &&
                sportCategoryData[1] === 'None') ||
              sportCategoryData?.error ||
              !sportCategoryDataFetched ? (
                () => refetchSubCategory()
              ) : (
                <div className="flex-1 m-1 mt-2">
                  <label className="font-semibold text-sm text-gray-750 pb-2 md:pb-1 block">
                    Select Sport Category
                  </label>
                  <div className="flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg">
                    <Field
                      as="select"
                      name="sportCategory"
                      onChange={(e) => {
                        setValues({ ...values, sportCategory: e.target.value });
                        setSportCategory(e.target.value);
                        refetchSportCategory();
                      }}
                      className="bg-red flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                    >
                      <option label="Select Sport Ctegory" value=""></option>

                      {sportCategoryData?.map((el, i) => (
                        <option key={i} value={el}>
                          {el}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
              )} */}
              <ErrorMessage name="sportCategory" component="div" className="flex-1 text-red-400" />
              {(subCategoryData?.length === 1 && subCategoryData[0] === 'None') ||
              (subCategoryData?.length === 2 &&
                subCategoryData[0] === 'None' &&
                subCategoryData[1] === null) ||
              (subCategoryData?.length === 2 &&
                subCategoryData[0] === null &&
                subCategoryData[1] === 'None') ||
              subCategoryData?.error ||
              !subCategoryDataFetched ||
              !subCategoryData?.length ? null : (
                <div className="flex-1 m-1 mt-2">
                  <label className="font-normal text-sm text-gray-750 pb-2 md:pb-1 block">
                    Sub Category
                  </label>
                  <div className="overflow-hidden flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg">
                    <Field
                      as="select"
                      name="subCategory"
                      onChange={(e) => {
                        setValues({ ...values, subCategory: e.target.value });
                        setsubCategory(e.target.value);
                        refetchSubCategory();
                      }}
                      required
                      className="text-gray-450 bg-red flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                    >
                      <option label="Select Sub Category" value=""></option>
                      {subCategoryData?.length > 0 &&
                        subCategoryData?.map((el, i) => (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        ))}
                    </Field>
                  </div>
                  <ErrorMessage
                    name="subCategory"
                    component="div"
                    className="flex-1 text-red-400"
                  />
                </div>
              )}
            </div>
          )}
          <div className="flex w-full flex-col center items-center justify-center w-full">
            <button
              data-cy="submit"
              type="submit"
              className="reg-btn-dark text-lg font-bold flex items-center justify-center mx-2 w-full md:mx-0 md:w-1/2 ml-1 rounded-lg my-8 px-10 py-2 text-white"
              disabled={isSubmitting}
            >
              {customSubmitText || 'View Draws & Schedules'}
              {isLoading ? (
                <img src={Rotate} className="ml-2 w-4 h-4 color-white animate-spin" />
              ) : (
                ''
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
