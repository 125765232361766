import React from 'react';
import AttractionCard from '../../Components/AttractionsCard';
// import playerImg from '../../../assets/img/tennis_player.png';
// import player2Img from '../../../assets/img/player_2.png';
// import player3Img from '../../../assets/img/player_3.png';
import ListLoader from 'components/ListLoader';
import { useEventAttractions } from 'micro-site/queries/hooks';
// import sportIcon from '../../../assets/img/sport_logo.svg';
// import twitter from '../../../assets/img/twitter.svg';
// import swimmer from '../../../assets/img/swimmer.png';
// import Golf from '../../../assets/img/golf.png';
// import Sprint from '../../../assets/img/sprint.png';
// import Relay from '../../../assets/img/relay.png';

// import Swimming from '../../../assets/img/swimming.png';
// import Tennis from '../../../assets/img/tennis.png';
// import Wrestling from '../../../assets/img/wrestling.png';
// import cycle from '../../../assets/img/cycle.png';

export default function Attractions() {
  // const data = [
  //   {
  //     date_time: '',
  //     tag: 'sheisgold',
  //     image: playerImg,
  //     video_link: '#',
  //     match_title: 'Girls Day Celebration',
  //     match_description:
  //       'Girls day celebration with Ace shutter Saina Nehwal. To watch full event click on watch video.',
  //     view_schedule: true,
  //   },
  //   {
  //     date_time: '',
  //     tag: 'sheisgold',
  //     image: player2Img,
  //     video_link: '#',
  //     match_title: 'Girls Day Celebration',
  //     match_description:
  //       'Girls day celebration with Ace shutter Saina Nehwal. To watch full event click on watch video.',
  //     view_schedule: true,
  //   },
  //   {
  //     date_time: '',
  //     tag: 'sheisgold',
  //     image: player3Img,
  //     video_link: '#',
  //     match_title: 'Girls Day Celebration',
  //     match_description:
  //       'Girls day celebration with Ace shutter Saina Nehwal. To watch full event click on watch video.',
  //     view_schedule: true,
  //   },
  //   {
  //     date_time: '',
  //     tag: 'sheisgold',
  //     image: playerImg,
  //     video_link: '#',
  //     match_title: 'Girls Day Celebration',
  //     match_description:
  //       'Girls day celebration with Ace shutter Saina Nehwal. To watch full event click on watch video.',
  //     view_schedule: true,
  //   },
  //   {
  //     date_time: '',
  //     tag: 'sheisgold',
  //     image: player2Img,
  //     video_link: '#',
  //     match_title: 'Girls Day Celebration',
  //     match_description:
  //       'Girls day celebration with Ace shutter Saina Nehwal. To watch full event click on watch video.',
  //     view_schedule: true,
  //   },
  //   {
  //     date_time: '',
  //     tag: 'sheisgold',
  //     image: player3Img,
  //     video_link: '#',
  //     match_title: 'Girls Day Celebration',
  //     match_description:
  //       'Girls day celebration with Ace shutter Saina Nehwal. To watch full event click on watch video.',
  //     view_schedule: true,
  //   },
  // ];

  const eventAttractionsListQueryData = useEventAttractions({
    tournament_id: '221',
  });

  console.log('eventAttractionsListQueryData', eventAttractionsListQueryData);

  return (
    <div className="px-4 md:px-16 pt-4 pb-14 max-w-fhd mx-auto">
      {/* <Tabs tabList={AttractionTab} color="text-purple-550" border="border-purple-550" /> */}

      <h3 className="text-lg md:pb-3 md:shadow-shadowBottom md:text-2xl font-bold text-gray-750 mt-2">
        Upcoming Attractions
      </h3>
      <hr className="w-16 mx-0 md:hidden my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      <div className="grid grid-flow-row mt-10 justify-items-stretch md:items-start grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-10">
        <ListLoader queryData={eventAttractionsListQueryData} queryKey="eventAttractions">
          {({ item, index }) => {
            return <AttractionCard key={index} data={item} />;
          }}
        </ListLoader>
      </div>

      {/* <div className="mt-10 md:mt-16">
        <h3 className="text-lg md:pb-3 md:shadow-shadowBottom md:text-2xl font-bold text-gray-750 mt-2">
          Social Media
        </h3>
        <hr className="w-16 mx-0 md:hidden my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
        <div className="mt-6 gap-4 flex">
          <div className="p-6 rounded-lg border border-gray-300 mx-auto">
            <div className="flex items-center mb-4 justify-between">
              <div className="flex gap-5 items-center">
                <img src={sportIcon} alt="sportIcon" />
                <div>
                  <p className="text-xs text-gray-750">Sport for All</p>
                  <p className="text-xs text-gray-450">@sfanow</p>
                </div>
              </div>
              <img src={twitter} alt="social_media_icon" />
            </div>
            <p className="text-xs text-gray-650 mb-2">Watch Swimming match on SFA play only. 🏊🏻‍♀️</p>
            <img src={swimmer} alt="swimmer_img" />
          </div>
        </div>
      </div> */}
    </div>
  );
}

// import React from 'react';
// import Tabs from '../../../components/Tab/index';
// import AttractionsComponent from '../../Components/Attractions';
// import LiveEvents from '../../Components/LiveEvents';
// import SocialMedia from '../../Components/SocialMedia';
// // import playerImg from '../../../assets/img/tennis_player.png';

// export default function Attractions() {
//   const AttractionTab = [
//     {
//       name: 'Attractions',
//       component: <AttractionsComponent />,
//     },
//     { name: 'Live Events', component: <LiveEvents /> },
//     { name: 'Social Media', component: <SocialMedia /> },
//   ];

//   return (
//     <div className="px-4 md:px-16">
//       <Tabs tabList={AttractionTab} color="text-purple-550" border="border-purple-550" />
//     </div>
//   );
// }
