import React from 'react';
import { render, hydrate } from 'react-dom';
import './index.css';
import App from './App';
import { gtmInitialize } from 'utils/gtmConfigUtils';

const rootElement = document.getElementById('root');

// check to run gtm only on production
if (
  window.location.origin.replace('https://', '') === 'app.sfaplay.com' ||
  window.location.origin.includes('compassionate-stonebraker')
)
  gtmInitialize();

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
