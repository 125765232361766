import Header from 'micro-site/Components/Header';
import Tabs from 'components/Tab';
import { getTimeIn12HourFormat } from 'constants/DateFunctions';
import { calculateConditions } from './index';
import infoIconGrey from '../../../assets/img/info_iconGrey.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
const Draws = ({ expandedView, setExpandedView, opponent, setOpponent }) => {
  const draw = expandedView && expandedView?.opponent_1?.won === expandedView?.opponent_2?.won;
  return (
    <>
      <Header
        hideTabs
        variant={'sport'}
        ageGroup={expandedView.sport_event.age_group}
        eventName={expandedView.sport_event.sport}
        time={getTimeIn12HourFormat(expandedView.match.time)}
        venue={expandedView.match.venue}
        gender={expandedView.sport_event.gender}
        img={expandedView.sport_event.sport_image}
      />
      <Tabs
        color="text-purple-550"
        border="border-purple-550"
        tabList={[
          { name: 'Opponent 1', component: <></>, callback: () => setOpponent('opponent_1') },
          { name: 'Opponent 2', component: <></>, callback: () => setOpponent('opponent_2') },
        ]}
      />
      <div className="p-4">
        <div
          className="font-bold flex gap-2 items-center my-2 cursor-pointer"
          onClick={() => setExpandedView(null)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          Draws and Schedules
        </div>
        <div className="flex flex-col items-center gap-2 shadow-shadowBottom pb-4">
          <div
            className={`w-30 h-30 rounded-full border-2 ${
              !draw ? (expandedView[opponent]?.won ? 'border-green-450' : 'border-red-350') : ''
            }
              relative`}
            style={{
              background: `url('${expandedView[opponent]?.image}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div
              className={`bottom-0 absolute ${
                !draw ? (expandedView[opponent]?.won ? 'bg-green-450' : 'bg-red-350') : ''
              } py-0.5 px-1.5 font-bold flex items-center fit-content justify-center text-xxs rounded text-white transform -translate-x-1/2 translate-y-1/2 left-1/2 whitespace-nowrap`}
            >
              {!draw
                ? calculateConditions(
                    expandedView[opponent]?.won,
                    expandedView.match.match_status,
                    draw
                  )
                : ''}
            </div>
          </div>
          <div className="py-0.5 px-1 flex gap-2 rounded bg-gray-300 mt-5 text-xxs text-gray-450 font-semibold">
            <img src={infoIconGrey} className="" />
            {expandedView?.match?.match_status}
          </div>
          <h3 className="text-lg font-bold">{expandedView[opponent]?.name}</h3>
          <p className="font-xs text-gray-650">{expandedView[opponent]?.institution}</p>
        </div>
        <h2 className="font-bold text-lg mt-4">Score</h2>
        <hr className="w-16 md:hidden mx-0 my-0 bg-gray-750 h-0.5 md:h-1 mt-1" />
        <div className="flex-col mt-4 rounded-lg overflow-hidden bg-active-schedule">
          <MobileScore
            name={expandedView?.opponent_1?.name}
            score={expandedView?.opponent_1?.score?.split('-')[0]}
            won={expandedView?.opponent_1?.won}
            image={expandedView?.opponent_1?.image}
            draw={draw}
          />
          {console.log(
            expandedView,
            expandedView?.opponent_1?.won === expandedView?.opponent_2?.won
          )}
          <MobileScore
            name={expandedView?.opponent_2?.name}
            score={expandedView?.opponent_1?.score?.split('-')[1]}
            won={expandedView?.opponent_2?.won}
            image={expandedView?.opponent_2?.image}
            draw={draw}
          />
        </div>
      </div>
    </>
  );
};
function MobileScore({ name, score, won, image, display, draw }) {
  return (
    <div className="py-4 px-4 flex gap-2 bg-gray-750 text-white items-center border-t-1 border-gray-625">
      {' '}
      <div
        className={`w-6 h-6 rounded-full border-2 ${
          !draw ? (won ? 'border-green-450' : 'border-red-350') : ''
        } relative`}
        style={{
          background: `url('${image}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
      <div className="flex justify-between w-full">
        <p className="text-sm">{name}</p>
        <p className="text-white text-xs">
          <span className="opacity-60">Score</span> {score !== 'NA' ? score || '-' : '-'}
        </p>
      </div>
    </div>
  );
}

export default Draws;
