import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import SelectList from 'components/select-list';
import Loader from 'components/Loader';
import 'components/formio/formio.css';
import 'constants/DrawEventForm/style.css';
import Table from '../../../Components/Table';

export default function RankingTable({
  sport,
  setSport,
  categoryList,
  rankingTableListFullQueryData,
  columnsTable,
  setScreen,
}) {
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  useEffect(() => {
    if (rankingTableListFullQueryData?.data) {
      const temp = [];
      for (let i = 0; i < rankingTableListFullQueryData.data.length; i += pageSize) {
        temp.push(rankingTableListFullQueryData.data.slice(i, i + pageSize));
      }
      setPagination(temp);
      setPage(0);
    }
  }, [rankingTableListFullQueryData.data]);
  return (
    <div className="md:mt-8 px-4 md:px-16">
      <div
        className="flex gap-2 cursor-pointer items-center mb-8"
        onClick={() => setScreen('main_page')}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
        <p className="font-bold">Result</p>
      </div>
      <div className="flex justify-between shadow-shadowBottom pb-2 items-center">
        <p className="text-2xl font-bold">Full Ranking Table</p>
      </div>
      <div className="flex justify-between mt-8">
        <div className="flex items-center gap-2.5">
          <div className="w-40">
            <SelectList
              selectedValue={sport}
              setSelectedValue={setSport}
              placeholder="Sport"
              listData={categoryList}
            />
          </div>
          {/* <div className="w-40">
                <SelectList
                  selectedValue={event}
                  setSelectedValue={setEvent}
                  placeholder="Event"
                  listData={categoryList}
                />
              </div>
              <div className="w-40">
                <SelectList
                  selectedValue={medal}
                  setSelectedValue={setMedal}
                  placeholder="Medal"
                  listData={categoryList}
                />
              </div>
              <div className="w-40">
                <SelectList
                  selectedValue={round}
                  setSelectedValue={setRound}
                  placeholder="Round"
                  listData={categoryList}
                />
              </div> */}
        </div>
        {/* <div className="flex gap-2 text-sm py-2.5 px-4 border-1 border-gray-350 rounded-lg items-center">
              <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
              <input
                type="text"
                className="border-0 h-5 text-sm placeholder-gray-450"
                placeholder="Search by School"
              />
            </div> */}
      </div>
      {/* <div className="flex justify-between gap-6 mt-8">
            {pointsMedalsQueryData ? (
              <PointsCard
                data={pointsMedalsQueryData.data.individual_points}
                head="Individual Points"
              />
            ) : (
              <Loader />
            )}
            {pointsMedalsQueryData ? (
              <PointsCard data={pointsMedalsQueryData.data.team_points} head="Team Points" />
            ) : (
              <Loader />
            )}
          </div> */}
      <div className="mt-4">
        {rankingTableListFullQueryData.data &&
        rankingTableListFullQueryData.data.length > 0 &&
        Array.isArray(rankingTableListFullQueryData.data) &&
        rankingTableListFullQueryData.isFetched ? (
          <>
            {' '}
            <Table
              columns={columnsTable}
              rows={pagination
                ?.slice(0, page + 1)
                .reduce((prev, current) => [...prev, ...current], [])}
              sport={sport}
            />
            <div className="flex my-3 text-black font-semibold ">
              {page !== pagination.length - 1 ? (
                <button
                  disabled={page + 1 === pagination.length}
                  className={`mx-2  cursor-pointer text-black font-semibold  px-4 py-1 rounded flex gap-2 items-center`}
                  onClick={() => {
                    if (
                      page < rankingTableListFullQueryData?.data?.length &&
                      rankingTableListFullQueryData?.data?.length / pageSize
                    )
                      setPage((el) => el + 1);
                  }}
                >
                  <p>View More Events</p> <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                </button>
              ) : null}
              {page !== 0 ? (
                <button
                  disabled={page === 0}
                  className={`mx-2 cursor-pointer text-black font-semibold  px-4 py-1 rounded flex gap-2 items-center`}
                  onClick={() => setPage(0)}
                >
                  <p>View Less Events</p> <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                </button>
              ) : null}
            </div>
          </>
        ) : rankingTableListFullQueryData.isFetching ? (
          <Loader />
        ) : (
          <div className="py-20 text-center w-full  font-roboto h-full">
            Keep a close watch on this space to find out the best school for sport in Uttarakhand.
          </div>
        )}
      </div>
    </div>
  );
}
