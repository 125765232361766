import React from 'react'; //
import { NavLink } from 'react-router-dom';

export default function Tabs({ tabList, color, border, baseUrl, withinCreateorManage }) {
  if (withinCreateorManage) return null;
  // else
  //   React.useEffect(() => {
  //     setTimeout(() => {
  //       // commented so it can be deployed also dont put useEffect in else if any problem ask anyone
  //       // document.querySelector(`#${window.location.pathname.split('/')[4]}`)?.scrollIntoView();
  //       window.scrollTo(0, 0);
  //     }, 200);
  //     // .scrollIntoView();
  //   }, []);
  return (
    <div className="no-scrollbar">
      <div className="flex w-full mt-6 lg:mt-0 lg:mx-0 font-roboto profile-subnav justify-between cursor-pointer bg-white text-center overflow-x-auto no-scrollbar">
        {tabList.map((el, idx) => {
          return (
            <NavLink
              to={{ pathname: `${baseUrl}/${el.route}`, state: el?.state || {} }}
              key={idx}
              // id={el.route.toLowerCase()} commented again
              activeClassName={`${color} ${border} border-b-4 font-bold flex-grow min-w-max cursor-pointer px-4 py-2`}
              className={
                el.disabled
                  ? 'text-gray-450 min-w-max border-b-1 border-gray-450 flex-grow disabled px-4 py-2 opacity-50'
                  : 'text-gray-450 min-w-max border-b-1 border-gray-450 flex-grow cursor-pointer px-4 py-2'
              }
              data-cy="nav_navbar"
            >
              {el.name}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}
