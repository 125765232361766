import React from 'react';
import { useMediaDetails } from '../../../queries/hooks';
import Loader from '../../../../components/Loader';

const ShowMedia = ({ mediaName, className, onClick, ImageOnly, divRef }) => {
  const mediaDetailsQuery = useMediaDetails({
    media_item: mediaName,
  });

  return mediaDetailsQuery.isLoading ? (
    <Loader />
  ) : mediaDetailsQuery?.data?.type === 'Image' || ImageOnly ? (
    <img
      ref={divRef}
      src={
        ImageOnly && mediaDetailsQuery?.data?.type === 'Video(youtube)'
          ? `https://img.youtube.com/vi/${mediaDetailsQuery?.data?.video_id}/hqdefault.jpg`
          : ImageOnly && mediaDetailsQuery?.data?.type === 'Video(vimeo)'
          ? `https://vumbnail.com/${mediaDetailsQuery?.data?.video_id}.jpg`
          : mediaDetailsQuery?.data?.image
      }
      alt={''}
      className={className}
      onClick={onClick}
    />
  ) : mediaDetailsQuery?.data?.type === 'Video(youtube)' ? (
    <iframe
      ref={divRef}
      className={'w-full h-auto md:w-auto md:h-full border-5 border-white'}
      style={{
        aspectRatio: '16/9',
      }}
      src={`https://www.youtube.com/embed/${mediaDetailsQuery?.data?.video_id}?autoplay=1`}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : mediaDetailsQuery?.data?.type === 'Video(vimeo)' ? (
    <iframe
      ref={divRef}
      src={`https://player.vimeo.com/video/${mediaDetailsQuery?.data?.video_id}?autoplay=1`}
      className={'w-full h-auto md:w-auto md:w-full border-5 border-white bg-white'}
      style={{
        aspectRatio: '16/9',
      }}
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    />
  ) : null;
  /*
  return (
    <DetailItemLoader query={mediaDetailsQuery}>
      {(item) => {
        console.log({ item });
        // return data.media_details.map((media) => {
        // return <img src={data.image} alt={data.name} className={className} />;
        return <></>;
      }}
    </DetailItemLoader>
  );
*/
};

export default ShowMedia;
