import QUERY_KEYS from './queryKeys';

import { useQuery } from 'react-query';
import {
  getAgeGroup,
  getBuilderIoPage,
  getDrawDateList,
  getDrawFormat,
  getEventAttractions,
  getFolderDetails,
  getFolderList,
  getGender,
  getMediaDetails,
  getMediaList,
  getParticipantList,
  getPointsMedals,
  getRankingTableList,
  getRules,
  getSectionDetails,
  getSectionList,
  getShowReconciliation,
  getSportCategory,
  getSportEvent,
  getSportEventBasicInfo,
  getSportEventList,
  getSportEventListDirect,
  getSportEventPriceDetails,
  getSportList,
  getSubCategory,
  getTournamentBasicInfo,
  getTournamentContactDetails,
  getTournamentDetails,
  getTournamentPriceDetails,
  getTournamentSportList,
  getWinnerTable,
} from 'micro-site/services';

export const useTournamentDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_DETAILS, params.tournament_id],
    () => getTournamentDetails(params),
    {
      retry: false,
    }
  );
};

export const useDrawFormat = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_DRAW_FORMAT,
      params.tournament,
      params?.sport,
      params?.gender,
      params?.age_group,
      params?.sport_category,
      params?.sub_category,
    ],
    () => getDrawFormat(params),
    {
      retry: false,
    }
  );
};

export const useSportList = (params) => {
  return useQuery([...QUERY_KEYS.GET_SPORT_LIST, params.tournament], () => getSportList(params), {
    retry: false,
  });
};

export const useGender = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_GENDER, params.tournament, params?.sport],
    () => getGender(params),
    {
      retry: false,
    }
  );
};

export const useAgeGroup = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_AGE_CATEGORY, params.tournament, params?.sport, params?.gender],
    () => getAgeGroup(params),
    {
      retry: false,
    }
  );
};

export const useSportCategory = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_SPORT_CATEGORY,
      params.tournament,
      params?.sport,
      params?.gender,
      params?.age_group,
    ],
    () => getSportCategory(params),
    {
      retry: false,
    }
  );
};

export const useSubCategory = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_SUB_CATEGORY,
      params.tournament,
      params?.sport,
      params?.gender,
      params?.age_group,
      params?.sport_category,
    ],
    () => getSubCategory(params),
    {
      retry: false,
    }
  );
};

export const useTournamentBasicInfo = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_BASIC_INFO, params.tournament_id],
    () => getTournamentBasicInfo(params),
    {
      retry: false,
    }
  );
};

export const useTournamentContactDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_CONTACT_DETAILS, params.tournament_id],
    () => getTournamentContactDetails(params),
    {
      retry: false,
    }
  );
};

export const useTournamentPriceDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_PRICE_DETAILS, params.tournament_id],
    () => getTournamentPriceDetails(params),
    {
      retry: false,
    }
  );
};

export const useTournamentSportList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_SPORT_LIST, params.tournamentId],
    () => getTournamentSportList(params),
    {
      retry: false,
    }
  );
};

export const useSportEventBasicInfo = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORT_BASIC_INFO, params.tournament, params.sport],
    () => getSportEventBasicInfo(params),
    {
      retry: false,
    }
  );
};

export const useSportEventListDirect = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORT_EVENTS_LIST_DIRECT, params.tournament, params.sport],
    () => getSportEventListDirect(params),
    {
      retry: false,
    }
  );
};

export const useSportEventList = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_SPORT_EVENTS_LIST,
      params.tournament,
      params.sport,
      params.age_group,
      params.gender,
      params.date,
      params.fees_range,
    ],
    () => getSportEventList(params),
    {
      retry: false,
    }
  );
};

export const useSportEventPriceDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORT_PRICE_DETAILS, params.tournament, params.sport],
    () => getSportEventPriceDetails(params),
    {
      retry: false,
    }
  );
};

export const usePointsMedals = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_POINTS_MEDALS, params.tournament],
    () => getPointsMedals(params),
    {
      retry: false,
    }
  );
};

export const useRankingTableList = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_RANKING_TABLE_LIST,
      params.tournament,
      params.preview,
      params.sport,
      params.event,
      params.round,
      params.medal,
    ],
    () => getRankingTableList(params),
    {
      retry: false,
    }
  );
};
export const useWinnerTable = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_WINNER_TABLE_LIST, params.tournament, params.recent, params.sport],
    () => getWinnerTable(params),
    {
      retry: false,
    }
  );
};

export const useParticipantList = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_PARTICIPANT_LIST,
      params.tournament,
      params.date,
      params.sport,
      params.round,
    ],
    () => getParticipantList(params),
    {
      retry: false,
    }
  );
};

export const useSportEvent = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_SPORT_EVENT,
      params?.tournament ? params?.tournament : '',
      params?.sport ? params?.sport : '',
      params?.gender ? params?.gender : '',
      params?.age_group ? params?.age_group : '',
      params?.sport_category ? params?.sport_category : '',
      params?.sub_category ? params?.sub_category : '',
    ],

    () => getSportEvent(params),
    {
      retry: false,
    }
  );
};

export const useDrawDateList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_DRAW_DATE_LIST, params.tournament],
    () => getDrawDateList(params),
    {
      retry: false,
    }
  );
};

export const useBuilderIoPage = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_BUILDERIO_PAGE, params.tournament],
    () => getBuilderIoPage(params),
    {
      retry: false,
    }
  );
};

export const useShowReconciliation = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SHOW_RECONCILIATION, params.tournament],
    () => getShowReconciliation(params),
    {
      retry: false,
    }
  );
};

export const useRulesAndPrice = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_RULES_PRICE, params.tournamentId, params.sport],
    () => getRules(params),
    { retry: false }
  );
};

export const useEventAttractions = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_EVENT_ATTRACTIONS, params.tournament_id],
    () => getEventAttractions(params),
    { retry: false }
  );
};
export const useSectionsList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SECTION_LIST, params.tournament_id],
    () => getSectionList(params),
    { retry: false }
  );
};
export const useSectionDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SECTION_DETAILS, params.section_name],
    () => getSectionDetails(params),
    { retry: false }
  );
};
export const useFolderList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_FOLDER_LIST, params.section_name],
    () => getFolderList(params),
    { retry: false }
  );
};
export const useFolderDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_FOLDER_DETAILS, params.folder_name],
    () => getFolderDetails(params),
    { retry: false }
  );
};
export const useMediaList = (params) => {
  return useQuery([...QUERY_KEYS.GET_MEDIA_LIST, params.folder_name], () => getMediaList(params), {
    retry: false,
  });
};
export const useMediaDetails = (params, enabled = true) => {
  return useQuery(
    [...QUERY_KEYS.GET_MEDIA_DETAILS, params.media_item],
    () => getMediaDetails(params),
    { retry: false, enabled: enabled }
  );
};
